.executives_data {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 30px;
    color: antiquewhite;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Adds a blur effect */
    border-radius: 10px; /* Rounded corners for aesthetics */
    padding: 20px; /* Adds padding for better spacing inside */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for better separation from the background */
}

.executives_data h3{
   font-size: 32px;
   margin-bottom: 2rem;
}



.executives_data ul{
    display: flex;
    flex-direction: column;
    /* width: 30%; */
}

.executives_data li{
    list-style: none;
    margin: 0.1rem 0;
}

@media (max-width: 1024px) {
    .executives_data{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 80%;
        margin: 30px;
    }

    .child_data{
        margin: 15px 0px;
    }
  }
  