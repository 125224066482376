
.headingContainer{
    background: #974EC382;
    height: 68px;
    /* width: 100vw; */
    top: 870px;
    left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading2{
    font-family: "kanit";
font-size: 40px;
font-weight: bold;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}

.ProblemStatements{
    display: flex;
    margin: 3rem 3rem 9rem 3rem;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.branch{
    width: 220px;
    height: 50px;
    font-size:18px;
    /* border-radius: 20px; */
    border: 4px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-weight: 500;
    border-image: linear-gradient(to right, #4c90d5, #FE7BE5); /* Define your linear gradient colors here */
    border-image-slice: 1; /* Keeps the entire gradient within the border */
    border-image-repeat: stretch; 
    margin: 1rem 0rem;
}


.branch:hover{
 color: white;
 background: linear-gradient(to right, #4c90d5, #FE7BE5);
 /* border: 2px solic;
 border-bottom-color:#FE7BE5 ;
 border-top-color: #504099 ; */
 cursor: pointer;
}

@media(max-width:768px){
    .ProblemStatements{
        margin: 3rem 2rem auto 2rem;
    }
    .branch{
        font-size: 20px;
        margin: 0;
    }
}

@media ( min-width: 1024px){
    .ProblemStatements{
        margin: 3rem 0rem 3rem 0rem;
    }
}

@media(max-width: 420px){
    .branch{
        width: 140px;
        font-size: 16px;
    }
    .headingContainer{
        height: 110px;
    }
}

.container{
    padding: 0 5rem;
}
@media(width<600px){
    .container{
        padding: 0;
    }
    .ProblemStatements{
        justify-content: center;
    }
}