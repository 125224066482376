@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.alert-popup {
    background: white;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px #00000033;
    max-width: 500px;
    width: 100%;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
}

.popup-heading {
    font-family: poppins;
    font-weight: 500;
    font-size: 30px;
}

.alert-message {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.alert-button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.alert-button:hover {
    background: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
