.top {
  backdrop-filter: blur(8px) saturate(178%);
  -webkit-backdrop-filter: blur(8px) saturate(178%);
  /* background-color: #9ddc89a8; */
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  /* border: 1px solid rgba(255, 255, 255, 0.125); */
  height: 500px;
  width: 100%;
  border: solid 2px #825290;
  /* box-shadow: 2px 2px 10px 2px #825290; */
  /* z-index: -1; */
  border-radius: 0px 0px 100px 100px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.top h1{
  font-size: 3rem;
  margin: 2rem;
  margin-top: 5rem;
  font-family: 'angora';
}
.top img{
  width: 40%;
}

.page {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
} 
@media(width<1024px){
.top{
  width: 500px;
  height: 300px;
}
}
@media(width<500px){
  .top{
      width: 280px;
      height: 200px;
  }
}

.TopCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.TCard{
  background-color: #C8BABA;
  height: 200px;
  width: 400px;
  margin:0 20px 40px 20px;
}

.MiddleCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.container{
  padding: 0 12rem;
  padding-bottom: 5rem;
}
@media(width<769px){
  .TCard{
      height: 120px;
      width: 250px;
      margin: 0 0 15px 0;
  }
  .TopCards {
      flex-direction: column;
      align-items: center;
  }
  .top{
      border-radius: 0px 0px 50px 50px;
      height: auto;
      width: 100%;
  }
  .container{
      padding: 0;
      padding-bottom: 2rem;
  }
  .top h1{
    font-size: 1.8em;
    margin: 0;
    margin-top: 7rem;
  }
  .top img{
    width: 90%;
    margin: 3rem 0;
  }
} 
