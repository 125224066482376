
.card{
    width: 430px;
    /* height: 500px; */
    cursor: pointer;
    background-color: rgba(255,255,255,.01);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255,255,255,.05);
    border-left: 1px solid rgba(255,255,255,.05);
    margin-top: 100px !important;
    backdrop-filter: blur(5px);
    border-radius: 25px;
}

.card:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.cardImage{
    height: 400px;
    width: 100% ;
    margin: 0;
    position: relative;
    top: -80px;
    object-fit: contain;
    overflow: hidden;
}


.cardBody{
    margin-top: -90px;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1.3;
    padding: 0 2rem 2rem 2rem;
}

.cardBody .topic div{
    font-weight: bold;
}

.cardBody p{
    margin: 0;
    font-weight: normal;
}

.cardBody>div:nth-child(3){
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 100;
        line-height: 1.7;
        font-size: 18px;
}
.cardBody>div:nth-child(4){
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 100;
        line-height: 1.7;
        font-size: 18px;
}
.cardBody>div:nth-child(5){
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 100;
        line-height: 1.7;
        font-size: 18px;
}
.cardBody>div:nth-child(6){
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 100;
        line-height: 1.7;
        font-size: 18px;
}

.cardText{
    font-size: 18px;
    font-weight:200;
    color: rgba(255, 255, 255, 0.522);
    margin: 1rem 0;
}

.cardTitle{
    color: #fff;
    font-size: 24px;
}

@media (max-width:1200px) {
   .card{
    width: 400px ;
    /* height: 330px ; */
   }
   .cardImage{
    width: 300px !important;
    height: 220px !important;
   }
}

@media(max-width:550px){
    .card{
        width: 80vw;
        /* height:  320px; */
    }
    .cardBody{
        margin-top: -80px;
        line-height: 1.5;
        padding: 0 1rem 1rem 1rem;
    }
    .cardTitle div{
        font-size: 23px;
    }
    .cardText div{
        font-size: 15px;
        line-height: 1.2;
        font-weight: 100;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }
    .cardBody>div:nth-child(3){
        font-size: 16px;
        font-weight: 100;
        line-height: 1.7;
    }
    .cardBody>div:nth-child(4){
        font-size: 16px;
        font-weight: 100;
        line-height: 1.7;
    }
    .cardBody>div:nth-child(5){
        font-size: 16px;
        font-weight: 100;
        line-height: 1.7;
    }
    .cardBody>div:nth-child(6){
        font-size: 16px;
        font-weight: 100;
        line-height: 1.7;
    }
}