.homecontainer {
  background-image: url("../../assets/images/HomePage/section1BG.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  gap: 25vmax;
}

.hero-vid{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}


.Home-sec1 {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.petal-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.title-cont {
  z-index: 2;
  // min-height: 60vh;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.img-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes home-title-ani {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  50% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-cont-inn {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  // animation-name: home-title-ani;
  // animation-duration: 2s;
  // animation-timing-function: ease-out;
}

.home-title {
  width: 100%;
  margin: 0;
}

.hm-title {
  font-family: "kung fu panda";
  font-size: 8vmax;
  gap: 40px;
  /* margin-bottom: 40px; */
  color: #e91006;
  text-shadow: #330000 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.hm-title .quote-cont {
  position: absolute;
  text-shadow: none;
}

.about-title .quote-cont{
  bottom: calc(180% + 8px);
  left: calc(60% - 32px);
}

.timeline-title .quote-cont {
  bottom: calc(160% + 8px);
  left: calc(25% - 32px);
}

.faq-title .quote-cont{
  bottom: calc(155% + 8px);
  left: calc(20% - 32px);
}

.hm-title .quote p {
  color: black;
  font-family: "Courier New", Courier, monospace;
  text-align: center;
  margin: 0;
}

$black: #000;
$white: #fff;
$shadow: rgba(0, 0, 0, 0.1);
$px: 4px;
$bubble-border: 0 -1 * $px $white, 0 -2 * $px $black, $px 0 $white,
  $px -1 * $px $black, 2 * $px 0 $black, 0 $px $white, 0 2 * $px $black,
  -1 * $px 0 $white, -1 * $px $px $black, -2 * $px 0 $black,
  -1 * $px -1 * $px $black, $px $px $black;

.quote {
  position: relative;
  display: inline-block;
  margin: 5 * $px;
  text-align: center;
  font-family: "Press Start 2P", cursive;
  font-size: 1vmax;
  line-height: 1.3em;
  // letter-spacing: -0.04em;
  background-color: $white;
  color: $black;
  padding: 1 * $px;
  box-shadow: $bubble-border;

  box-sizing: border-box;
  width: 200px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
  }

  &.shadow {
    box-shadow: $bubble-border, $px 3 * $px $shadow, 3 * $px $px $shadow,
      2 * $px 2 * $px $shadow;
  }

  &.mini {
    width: 110px;
    font-size: 16px;
    padding: 4px;
    font-family: monospace;
  }
  &.medium {
    width: 350px;
  }
  &.large {
    width: 560px;
    font-size: 24px;
    text-align: left;
    text-transform: uppercase;
  }
  &.grow {
    width: initial;
  }
  &.right {
    display: none;
  }
  &.top::after {
    height: $px;
    width: $px;
    top: -2 * $px;
    left: 8 * $px;
    box-shadow: 0 -1 * $px $black, 0 -2 * $px $black, 0 -3 * $px $black,
      0 -4 * $px $black, -1 * $px -3 * $px $black, -2 * $px -2 * $px $black,
      -3 * $px -1 * $px $black, -1 * $px -1 * $px $white,
      -2 * $px -1 * $px $white, -1 * $px -2 * $px $white, -1 * $px 0 $white,
      -2 * $px 0 $white, -3 * $px 0 $white;
  }

  &.right::after {
    height: $px;
    width: $px;
    top: 21 * $px;
    right: -2 * $px;
    background: white;
    box-shadow: 1 * $px -1 * $px $white, 1 * $px 0 $white, 2 * $px 0 $white,
      0 -2 * $px $white, 1 * $px 1 * $px $black, 2 * $px 1 * $px $black,
      3 * $px 1 * $px $black, 4 * $px 1 * $px $black, 3 * $px 0 $black,
      2 * $px -1 * $px $black, 1 * $px -2 * $px $black, 0 -1 * $px $white;
  }

  &.bottom::after {
    height: $px;
    width: $px;
    bottom: -2 * $px;
    left: 8 * $px;
    box-shadow: 0 $px $black, 0 2 * $px $black, 0 3 * $px $black,
      0 4 * $px $black, -1 * $px 3 * $px $black, -2 * $px 2 * $px $black,
      -3 * $px 1 * $px $black, -1 * $px $px $white, -2 * $px $px $white,
      -1 * $px 2 * $px $white, -1 * $px 0 $white, -2 * $px 0 $white,
      -3 * $px 0 $white;
  }

  &.left::after {
    height: $px;
    width: $px;
    top: 5 * $px;
    left: -2 * $px;
    background: white;
    box-shadow: -1 * $px -1 * $px $white, -1 * $px 0 $white, -2 * $px 0 $white,
      0 -2 * $px $white, -1 * $px 1 * $px $black, -2 * $px 1 * $px $black,
      -3 * $px 1 * $px $black, -4 * $px 1 * $px $black, -3 * $px 0 $black,
      -2 * $px -1 * $px $black, -1 * $px -2 * $px $black, 0 -1 * $px $white;
  }
}

@media (max-width: 768px) {
  .hm-title {
    font-size: 15vw;
    gap: 20px;
  }
  .timeline-title{
    font-size: 14.5vw;
  }
  // .hm-title .quote-cont {
  //   display: none;
  // }
  .hm-title .quote{
    width: 100px;
  }
}


@keyframes titleAnim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20%);
  }
}

.hm-title span {
  display: inline-block;
  transform-origin: bottom;
  animation: titleAnim 1s linear infinite;
  /* animation-delay: calc(var(--i) * 0.15s); */
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.15s;
    }
  }
  position: relative;
}

.hm-title img {
  position: absolute;
  //   height: 80%;
  width: 110%;
  top: -119%;
  left: 10%;
}

.timeline-title img {
  height: 80%;
  left: 0%;
  width: auto;
  top: -60%;
}
.about-title img{
  left: 20%;
  top: -82%;
  
}
.faq-title img {
  width: auto;
  height: 80%;
  top: -60%;
  left: 30%;
}

@keyframes po-ani {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}
@keyframes po-entry-ani {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.po-img {
  height: 18vw;
  animation: po-ani 4s ease-in-out 2s infinite, po-entry-ani 2s linear;
}

.stone {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  transition: color 0.2s ease-in-out, background-image 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  background-image: url("../../assets/images/stone.png");
  color: yellow;
}

.stone:hover {
  transform: scale(1.1);
  background-image: url("../../assets/images/stone2.png");
  color: rgb(126, 59, 59);
}

.stone:active {
  transform: scale(1);
}

@keyframes btn-cont-ani {
  0% {
    transform: scalex(0);
    opacity: 0;
  }
  50% {
    transform: scalex(0);
    opacity: 0;
  }
  100% {
    transform: scalex(1);
    opacity: 1;
  }
}

.btn-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
  /* transform: scalex(0); */
  // animation-name: btn-cont-ani;
  // animation-duration: 2s;
  // animation-timing-function: linera;
}

.btn-sec1 {
  height: 74px;
  width: 30%;
  cursor: pointer;
  font-size: 35px;
  font-family: "chisled", Courier, monospace;
  border: solid red 6px;
  box-shadow: 5px 5px 3px black;
  border-radius: 30px;

  transition: all .2s ease;
}
.btn-sec1:hover{
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .Home-sec1{
    // justify-content: flex-start;
  }
  .title-cont{
    // height: 60%;
    height: auto;
  }
  .title-cont-inn {
    width: 95%;
  }

  .home-title {
    min-height: 15vh;
    margin-bottom: 50px;
  }

  .po-img {
    height: 25vh;
  }
  .btn-cont{
    // margin-top: 30px;
    flex-direction: column;
    gap: 20px;
  }
  .btn-sec1 {
    width: 80%;
    height: 50px;
    font-size: 22.5px;
  }
}
