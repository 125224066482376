.frow{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	/* z-index: 200; */
}

.footercol{
	padding: 3rem 0;
	z-index: 15;
}

ul{
	list-style: none;
	
}
.footer{
	backdrop-filter: blur(8px) saturate(178%);
    -webkit-backdrop-filter: blur(8px) saturate(178%);
    background-color: rgba(98, 74, 156, 0.485);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 50px 50px 60px 50px;
	/* border-top: 0.5px solid whitesmoke; */
	/* position: relative; */
	z-index: 1000;
}

.fcontainer{
	margin: 0;	
}

.footercol h4{
	font-size: 20px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 30px;
	font-weight: 300;
	position: relative;
	/* font-family: morden-warface; */
}
.footercol h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #FE7BE5D1;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
	transition: all 0.3s ease;
}

.footercol h4:hover::before{
	width: 200px;
}

.footercol ul li:not(:last-child){
	margin-bottom: 10px;
}
.footercol ul li{
	display: flex;
	/* justify-content: start; */
}
.footercol ul li a{
	font-size: 18px;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	display: block;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
}

.footercol ul li a:hover{
	color: #FE7BE5D1;
	/* padding-left: 8px; */
	transform: translateX(8px);
}
.footercol .fsociallinks a{
	display: inline-block;
	height: 40px;
	width: 40px;
	/* background-color: rgba(255,255,255,0.2); */
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	/* border-radius: 50%; */
	transition: all 0.5s ease;
}
.footercol .fsociallinks a:hover{
	transform: scale(1.3);
}

.logosec{
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 25%;
	padding-top: 0;
	padding: 1rem;
}

.logosec img{
	width: 100%;
	/* margin: 0 3rem; */
}
.logosec{
	padding: 1rem;
	/* margin: 0 3rem; */
}

.fquotessec{
	margin:1rem 1rem;
}

.fquotes{
	font-weight: 400;
	font-style: italic;
	font-size: 18px;
}

.fsaidby{
	float: right;
	font-weight: 400;
	font-size: 17px;

}
.fsaidby:hover{
	padding-right: 8px;
	transition: all 0.5s ease;
}

/*responsive*/
@media(max-width: 900px){
  .footercol{
    width: 50%;
    margin-bottom: 30px;
}
.f-quotessec{
	margin-left: 0rem;
}
}
@media(max-width: 700px){
  .footercol{
    width: 100%;
	align-items: center;
	padding: 1rem 0;
}
.footercol h4{
	text-align: center;
}
.footercol h4::before{
	width: 100%;
	text-align: center;
}
.footercol ul{
	text-align: center;
	/* align-items: center; */
}
.fsociallinks{
	display: flex;
	justify-content: space-around;
}
}

.linkss{
	padding: 0 1rem 0 0;
	background: -webkit-linear-gradient(#2FA4FF, #00FFDD);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.linkss2{
	background: -webkit-linear-gradient(#2FA4FF, #11efd2);
  -webkit-background-clip: text;
background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linker{
	font-size: 15px;
}

.footercol ul li{
	display: flex;
}

/* .techteamlink a{
	text-decoration: none;
	position: absolute;
	color: whitesmoke;
	padding-top: 80rem;
	font-size: 0.8em;
} */

@media(width<900px){
	/* .techteamlink{
		padding-top: 2rem;
	} */
	.footercol ul li{
		display: flex;
		justify-content: center;
	}
}

/* .techteamlink a::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #e91e63;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
	transition: all 0.3s ease;
}

.techteamlink a:hover::before{
	width: 280px;
	cursor: pointer;
} */

.techTeam{
	bottom: 30px;
	position: absolute;
}

.techTeam::before{
	content: '';
	position: absolute;
	right:0;
	bottom: -10px;
	background-color: #FE7BE5D1;
	height: 2px;
	box-sizing: border-box;
	width: 90px;
	text-emphasis-style: none;
	text-decoration: none;
	transition: all 0.3s ease;
}
.techTeam:hover::before{
	width: 280px;
	cursor: pointer;
}
.techTeam a{
	margin-top: 80rem;
	font-size: 20px;
	color: #ffffff;
	font-weight: 300;
	text-decoration: none;	
}
.magic{
	color: #FE7BE5D1;
	font-weight: 900;
}
.footercol{
	margin-bottom: 0px;
}
.techanchor{
	background: linear-gradient(90deg, rgba(248,220,120,0.9) 0%, rgba(67,177,248,0.9) 0%, rgba(233,30,99,0.9) 80%);
	background: ( ) no-repeat;
 -webkit-background-clip: text;
 background-clip: text;
 color: transparent;
 font-size: 1.2rem;
 padding: 2px;
 display: inline-block;

}