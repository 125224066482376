.vigyaan--card {
    width: 350px;
   height: 350px;
   font-size:18px;
   /* border-radius: 20px; */
   border: 4px solid;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: #FFF;
   font-weight: 500;
   backdrop-filter: blur(8px) saturate(178%);
   -webkit-backdrop-filter: blur(8px) saturate(178%);
   background-color: rgba(94, 94, 94, 0.309);
   border-bottom-left-radius: 12px;
   border-bottom-right-radius: 12px;
   border: 1px solid rgba(255, 255, 255, 0.125);
   margin: 1rem 0rem;
   border-radius: 20px;
   z-index: 1;
}

.heading{
   text-align: center;
   padding: 0 20px;
}


/* .vigyaan--card::before {
   content: "";
   width: 100%;
   height: 100%;
   opacity: 0.5;
   position: absolute;
   top: 0;
   left: 0;
} */

/* .vigyaan--card>div:first-child{
   position:absolute;
   top: 15%;
   left: 30%;
}
.vigyaan--card>div:last-child{
   position:absolute;
   bottom: 20%;
   left: 37%;
} */
.vigyaan--card>div>h3{
  margin: 1rem 0;
  font-size: 20px;
}
.vigyaan--card>div>h4{
   color: white;
   font-size: 26px;
}