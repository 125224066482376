.MerchSection{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
}

.MerchContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.MerchButtons{
    display: flex;
    gap: 40px;
}

.merch{
    width: 180px;
    height: 50px;
    font-size:18px;
    /* border-radius: 20px; */
    border: 4px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-weight: 500;
    border-image: linear-gradient(to right, #4c90d5, #FE7BE5); /* Define your linear gradient colors here */
    border-image-slice: 1; /* Keeps the entire gradient within the border */
    border-image-repeat: stretch; 
    margin: 1rem 0rem;
}

@media(max-width: 960px){
    .MerchSection{
        flex-direction: column-reverse;
    }
    .MerchButtons{
        gap: 15px;
    }
    .description{
        margin: auto 20px;
    }
}

.Merchimage{
    width: 350px;
    height: 400px;
    margin-top:2rem;
}

@media(max-width: 900px){
    .Merchimage{
       width: 260px;
       height: 320px;
    }
    
}