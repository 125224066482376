@font-face {
    font-family: 'Minion';
    src: url('../assets/font/Roundo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  
  .ptitle {
    color: rgba(233, 215, 15, 0.838);
    margin: 50px 0px 50px 0px;
    position: relative;
    text-align: center;
    z-index: 3;
    font-family: 'Minion';
    
    /* Add padding to give some space around the text */
    padding: 5px 20px;
    
    /* Create a semi-transparent background */
    background-color: rgba(0, 0, 0, 0.5);
    
    /* Add backdrop filter for blur effect */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); /* For Safari */
    
    /* Rounded corners for a softer look */
    border-radius: 8px;
    
    /* Add a subtle text shadow for better contrast */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  
  .ptitle h1{
    font-size: 2.8rem;
    color: rgb(255, 200, 0);
    text-decoration: solid blue 2px;
    font-family: 'Minion';
  }
  
  .pheading {
    font-size: 4rem;
    color: rgb(242, 182, 40);
    font-family: "Pokemon";
    padding-top: 20px;
  }
  .pheading span {
    
              font-family: 'Minion';
              /* font-style: yellow;  */
              
              background: linear-gradient(to bottom, #ffffff 20%,
                    #ffffff 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;

  
  }
  
  @media(width<769px){
    .heading{
      padding: 0;
      margin: 0;
    }
    .ptitle h1{
      font-size: 1.8rem;
    }
    .ptitle{
      margin-bottom: 0;
    }
  
  }
  @media(width<600px){
    .pheading{
      padding: 0;
      margin: 0;
    }
    .ptitle h1{
      font-size: 2.2rem;
    }
    .ptitle{
      /* margin: 0; */
    }
  
  }
  @media(width<426px){
    .pheading{
      padding: 0;
      margin: 0;
    }
    .ptitle h1{
      font-size: 1.6rem;
    }
    .ptitle{
      /* margin: 0; */
    }
  
  }