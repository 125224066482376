* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 7px 14px;
  z-index: 1;
  width: 100%;
  position: fixed;
  z-index: 2000;
}
.navlogo {
  height: 65px;
  width: 200px;
}
#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}
#navbar li:hover {
  cursor: pointer;
}
#navbar li a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  position: relative; /* Added for stacking order */
}
#navbar li a:before {
  content: "";
  position: absolute;
  background-color: #FE7BE5D1;
  height: 2px;
  width: 0;
  right: 0;
  top: -10px;
  transition: 0.3s;
}
#navbar li a:after {
  content: "";
  position: absolute;
  background-color: #FE7BE5D1;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}
#navbar li.active-link a:before,
#navbar li.active-link a:after {
  width: 100%; 
}
#navbar li a:hover:before {
  width: 100%;
}
#navbar li a:hover:after {
  width: 100%;
}
.responsive-open {
  display: none;
}
.responsive-close {
  display: none;
}
.responsive-open i {
  color: #fff;
  align-items: center;
}
.responsive-close i {
  color: #fff;
  align-items: center;
}
@media screen and (max-width: 848px) {
  #navbar li a {
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 500;
    color: #fff;
    position: relative; /* Added for stacking order */
  }
}
@media screen and (max-width: 772px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(12px);
    background: rgba(7, 85, 91, 0.253);
    padding: 40px 0 0 10px;
    transition: ease-out;
    z-index: 9999999;
  }
  #bars {
    font-size: 40px;
    color: white;
  }
  #navbar.active {
    right: 0vw;
  }
  #navbar li {
    margin-bottom: 35px;
  }
  .responsive-open {
    display: block;
  }
  .responsive-open i {
    font-size: 24px;
    cursor: pointer;
  }
  .responsive-close i {
    font-size: 30px;
    cursor: pointer;
  }
  .responsive-close {
    position: absolute;
    display: block;
    bottom: calc(100% - 70px);
    left: calc(100% - 50px);
  }
}

.scrolled {
  backdrop-filter: blur(8px) saturate(178%);
    -webkit-backdrop-filter: blur(8px) saturate(178%);
    background-color: rgba(98, 74, 156, 0.485);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
  transform: translateY(0%); 

  padding: 7px 14px;
  z-index: 1;
  width: 100%;
  position: fixed;
  height: 85px;
  z-index: 10;
  transition: transform 0.3s ease;
}
.notscrolled {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: 1;
  width: 100%;
  position: fixed;
  z-index: 9999999;
}
