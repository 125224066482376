.about_container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    scroll-snap-align: start;
}

.about_cont_in{
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.about_des_out {
	border-radius: 20px;
}

.about_des_in {
    max-width: 900px;
	margin: 0 auto;
	text-align: center;
}

.about_des_in>p{
    /* color: aqua; */
    color: #FFFFC7;
    text-shadow: #330000 1px 1px;
}

.about_boxes{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* margin: 2rem 0; */
    gap: 10px;
}

@keyframes shadowAnim {
	0%{
			box-shadow: 0px 5px 20px rgba(135, 223, 255, 0.8);
	}
	25%{
			box-shadow: 0px 5px 20px rgba(135, 255, 155, 0.8);
	}
	50%{
			box-shadow: 0px 5px 20px rgba(255, 255, 135, 0.8);
	}
	75%{
			box-shadow: 0px 5px 20px rgba(255, 213, 135, 0.8);
	}
	100%{
			box-shadow: 0px 5px 20px rgba(135, 223, 255, 0.8);
	}
}


.about_box{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 3rem 2rem 0 2rem;
    animation: shadowAnim 2s infinite;
    border: 1px solid rgba(255,255,255,.15);
    border-radius: 30px;
    padding: 15px;
    animation: shadowAnim 2s infinite;
    border: 1px solid rgba(255,255,255,.15);
    border-radius: 30px;
    padding: 15px;
    width: 25%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.abox_icon{
    width: 20%;
    width: 20%;
}
.abox_Title{
    font-family: Brolink2;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 600;
    /* margin-top: 0.5rem; */
    /* text-shadow: 2px 2px 10px black; */
    text-shadow: #FFFFC7 .5px .5px;
    /* color: aqua; */
    color: #252627;
    /* text-shadow: 2px 2px 10px black; */
    text-shadow: #FFFFC7 .5px .5px;
    /* color: aqua; */
    color: #252627;
}
.abox_num{
    font-family: "kanit";
    font-size: 2.5rem;
    color: white;
    letter-spacing: 1px;
    font-weight: 900;
    /* margin-top: 1rem; */
    /* margin-top: 1rem; */
    text-shadow: 1px 1px 5px black;
    text-align: center;
}

@media screen and (max-width :1005px) {
    .about_box{
        width: 170px;
    }
    .abox_Title{
    width: 170px;
    text-align: center  ;
    }
    .abox_icon{
        width: 40%;
    }
    .abox_num{
        font-size: 2rem;
    }
}
@media screen and (max-width:550px) {
    .abox_icon{
        width: 60%;
    }
    .abox_num{
        width:100px ;
        text-align: center;
    }
    .about_main{
        font-size: 1.4rem;
        width: 75vw;
    }
}
@media screen and (max-width:500px) {
    .about_main{
        width: 80vw;
        font-size: 1.3em;
    }
    .title_with_bg{
        font-size: 5rem;
        text-align: center;
    }
    .about_container{
        padding-bottom: 0;
    }
}
@media screen and (max-width:385px) {
    .about_main{
        width: 85vw;
        font-size: 1.2rem;
    }
    .title_with_bg{
        font-size: 4rem;
        text-align: center;
    }
}
@media screen and (max-width:330px) {
    .about_main{
        font-size: 1.1rem;
    }
    .title_with_bg{
        font-size: 3.5rem;
        text-align: center;
    }
}

@media(width<600px){
    .abox_Title{
        font-size: 0.8rem;
        font-weight: 300;
        width: 100%;
    }
    .abox_num{
        font-size: 1.5rem;
        font-weight: 600;
        width: 100%;
    }
    .about_boxes{
        margin-left: 0;
        margin-right: 0;
        /* flex-direction: column; */
    }
    .about_box{
        margin: 10px 0;width: 40%;
        margin: 10px 0;width: 40%;
    }
    .abox_icon{
        width: 40%;
    }
}