@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
.title{
  color:rgb(255, 255, 255);
  margin:50px 0px 50px 0px;
  position:relative;
  text-align: center;
  z-index: 3;
  font-family: 'AavartanFont';
  font-family: 'AavartanFont';
}

.title h1{
  font-size: 3.4rem;
  /* font-family: 'AavartanFont'; */

}

.heading {
  font-size: 4.5rem;
  
  /* color: rgba(255, 255, 255, 0.7); */
  padding-top: 20px;
}
.heading span {
  background: linear-gradient(to bottom, #3c107d 10%,
                    #13082a 100%);
                    font-family: "Orbitron", serif;
                    font-weight:bolder;
                    font-size:2.5rem;
                    text-shadow: 4px 4px 2px rgb(255, 255, 255);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            /* font-family: 'AavartanFont'; */
}

@media(width<769px){
  .heading{
    padding: 0;
    margin: 0;
  }
  .title h1{
    font-size: 1.8rem;
  }
  .title{
    margin-bottom: 0;
  }

}
@media(width<600px){
  .heading{
    padding: 0;
    margin: 0;
  }
  .title h1{
    font-size: 2.2rem;
  }
  .title{
    /* margin: 0; */
  }

}
@media(width<426px){
  .heading{
    padding: 0;
    margin: 0;
  }
  .title h1{
    font-size: 1.6rem;
  }
  .title{
    /* margin: 0; */
  }

}

