@charset "utf-8";

:root {
	--mc1: #cc00ff;
	--mc2: #7000ff;
	--hc: #e6e6e6ef;
	--bc: #d8dae6d2;
	--hff: "kanit";
	--bff: "kanit";
}

html {
	overflow-x: hidden;
	padding: 0px;
	margin: 0px;
	scroll-behavior: smooth;
}

*:after,
*:before,
* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

.metaportal_fn_content {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	min-height: 100vh;
}

#footer {
	margin-top: auto;
}


/* Preloader */
.metaportal_fn_preloader {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(27, 18, 29);
	background: -moz-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b121d", endColorstr="#1b121d", GradientType=1);
	transition: all .5s ease;
}

.metaportal_fn_preloader.ready {
	opacity: 0;
	visibility: hidden;
}

.metaportal_fn_preloader .loading-container {
	width: 256px;
	height: 256px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.metaportal_fn_preloader .loading {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 36%;
	left: 36%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.metaportal_fn_preloader .loading div {
	width: 32px;
	height: 32px;
	position: absolute;
}

.metaportal_fn_preloader .l1 div,
.metaportal_fn_preloader .l2 div,
.metaportal_fn_preloader .l3 div,
.metaportal_fn_preloader .l4 div {
	width: 100%;
	height: 100%;
	background-color: var(--mc1);
}

.metaportal_fn_preloader .l1 {
	transform: translate(0, 0);
}

.metaportal_fn_preloader .l2 {
	transform: translate(0, 32px);
}

.metaportal_fn_preloader .l3 {
	transform: translate(32px, 0px);
}

.metaportal_fn_preloader .l4 {
	transform: translate(32px, 32px);
}

@keyframes rot1 {
	0% {
		transform: rotate(0deg);
	}

	40% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(0deg);
	}

	60% {
		transform: rotate(90deg);
	}

	100% {
		transform: rotate(90deg);
	}
}

@keyframes rot2 {
	0% {
		transform: rotate(0deg);
	}

	40% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(0deg);
	}

	60% {
		transform: rotate(-90deg);
	}

	100% {
		transform: rotate(-90deg);
	}
}

@keyframes rot3 {
	0% {
		transform: rotate(45deg);
	}

	35% {
		transform: rotate(45deg);
	}

	65% {
		transform: rotate(405deg);
	}

	100% {
		transform: rotate(405deg);
	}
}

@keyframes l1-rise {
	0% {
		transform: translate(0px, 0px);
	}

	30% {
		transform: translate(-8px, -8px);
	}

	70% {
		transform: translate(-8px, -8px);
	}

	100% {
		transform: translate(0px, 0px);
	}
}

@keyframes l2-rise {
	0% {
		transform: translate(0, 32px);
	}

	30% {
		transform: translate(-8px, 40px);
	}

	70% {
		transform: translate(-8px, 40px);
	}

	100% {
		transform: translate(0, 32px);
	}
}

@keyframes l3-rise {
	0% {
		transform: translate(32px, 0px);
	}

	30% {
		transform: translate(40px, -8px);
	}

	70% {
		transform: translate(40px, -8px);
	}

	100% {
		transform: translate(32px, 0px);
	}
}

@keyframes l4-rise {
	0% {
		transform: translate(32px, 32px);
	}

	30% {
		transform: translate(40px, 40px);
	}

	70% {
		transform: translate(40px, 40px);
	}

	100% {
		transform: translate(32px, 32px);
	}
}

@keyframes color {
	0% {
		background-color: var(--mc1);
	}

	50% {
		background-color: var(--mc2);
	}

	100% {
		background-color: var(--mc1);
	}
}

.metaportal_fn_preloader .l1 {
	animation: l1-rise 3s ease 0s infinite;
}

.metaportal_fn_preloader .l2 {
	animation: l2-rise 3s ease 0s infinite;
}

.metaportal_fn_preloader .l3 {
	animation: l3-rise 3s ease 0s infinite;
}

.metaportal_fn_preloader .l4 {
	animation: l4-rise 3s ease 0s infinite;
}

.metaportal_fn_preloader .l1 div,
.metaportal_fn_preloader .l4 div {
	animation: rot1 3s ease 0s infinite, color 3s linear 0s infinite;
}

.metaportal_fn_preloader .l2 div,
.metaportal_fn_preloader .l3 div {
	animation: rot2 3s ease 0s infinite, color 3s linear 0s infinite;
}


/* 
/* body{
	font-family: var(--bff);
	font-size: 20px;
	letter-spacing: 0;
	line-height: 1.44;
	font-weight: 400;
	color: var(--bc);
	position: relative;
	word-break: break-word;
	/* background: rgb(27,18,29);
	background: -moz-linear-gradient(90deg, rgba(27,18,29,1) 0%, rgba(18,15,47,1) 50%, rgba(27,18,29,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(27,18,29,1) 0%, rgba(18,15,47,1) 50%, rgba(27,18,29,1) 100%);
	background: linear-gradient(90deg, rgba(27,18,29,1) 0%, rgba(18,15,47,1) 50%, rgba(27,18,29,1) 100%); */
/* background: url("./assets/images/homebg.webp") ;
	background-size: cover !important;
	background-attachment: fixed !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b121d",endColorstr="#1b121d",GradientType=1); */

body,
html {
	width: 100%;
	margin: 0px;
	padding: 0px;
}

body {
	height: auto;
	overflow-x: hidden;
}

html:after,
html:before {
	clear: both;
	display: table;
	content: '';
}

body:after {
	/* content: '';
	position: fixed;
	width: 270px;
	height: 270px;
	top: 42.8%;
	right: 25%; 
	background-color: var(--mc2);
	border-radius: 100%;
	-webkit-filter: blur(150px);
	-o-filter: blur(150px);
	filter: blur(150px); */
}

body:before {
	/* content: '';
	position: fixed;
	width: 270px;
	height: 270px;
	top: 27.5%;
	left: 23.9%; 
	background-color: var(--mc1);
	border-radius: 100%;
	-webkit-filter: blur(150px);
	-o-filter: blur(150px);
	filter: blur(150px); */
}

.fn__svg {
	fill: currentcolor;
	width: 18px;
	height: 18px;
	opacity: 0;
}

.replaced-svg {
	opacity: 1;
}

p {
	letter-spacing: 0;
	margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--hc);
	font-family: var(--hff);
	line-height: 1.22;
	letter-spacing: 0.5px;
}

h1>a,
h2>a,
h3>a,
h4>a,
h5>a,
h6>a {
	text-decoration: none;
	color: var(--hc);
}

h1>a:hover,
h2>a:hover,
h3>a:hover,
h4>a:hover,
h5>a:hover,
h6>a:hover {
	color: var(--mc1);
}

input[type="password"],
input[type="text"] {
	background-color: transparent;
	border-radius: 5px;
	border: 2px solid rgba(255, 255, 255, .15);
	font-size: 16px;
	letter-spacing: 0;
	line-height: 46px;
	height: 50px;
	padding: 0 15px;
	outline: none;
	outline-color: transparent;
}

textarea {
	background-color: transparent;
	border-radius: 6px;
	border: 2px solid rgba(255, 255, 255, .15);
	font-size: 16px;
	letter-spacing: 0;
	padding: 13px 15px;
	height: 250px;
}

textarea:focus,
input[type="password"]:focus,
input[type="text"]:focus {
	outline: none;
	background-color: transparent;
	border-color: var(--mc1);
}

/*------------------------------------------------------------------*/
/*	02) Container
/*------------------------------------------------------------------*/
.container {
	width: 100%;
	max-width: 1800px;
	padding: 0 100px;
	margin: 0 auto;
}

.container.small {
	max-width: 1400px;
}

.container:after,
.clearfix:after,
.clearfix:before {
	content: '';
	clear: both;
	display: table;
}

@media(max-width: 1200px) {
	.container {
		padding: 0 40px;
	}
}

@media(max-width: 1040px) {
	.container {
		padding: 0 20px;
	}
}

@media(max-width: 480px) {
	.container {
		padding: 0 10px;
	}
}

.mw300 {
	max-width: 300px;
}

/*------------------------------------------------------------------*/
/*	02) Some codes
/*------------------------------------------------------------------*/
a.full_link {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.bg_overlay .bg_image,
.bg_overlay .bg_color,
.bg_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.abs_img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.bg_overlay .bg_color {
	z-index: 2;
}

[class*="mw_"] {
	margin-left: auto;
	margin-right: auto;
}

.mw_650 {
	max-width: 650px;
}

.mw_500 {
	max-width: 500px;
}

/*------------------------------------------------------------------*/
/*	02) Site Structure
/*------------------------------------------------------------------*/
.metaportal_fn_main,
.metaportal_fn_main * {
	box-sizing: border-box;
}

.metaportal_fn_main {
	position: relative;
	z-index: 2;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	min-height: 100vh;
}


/* Mobile Navigation */
.metaportal_fn_mobnav {
	display: none;
	overflow: hidden;
}

.metaportal_fn_mobnav .social_trigger {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_mobnav .social_trigger .trigger {
	margin-right: 20px;
}

.metaportal_fn_mobnav .mob_mid,
.metaportal_fn_mobnav .mob_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	padding: 20px;
	overflow: hidden;
	background: rgb(27, 18, 29);
	background: -moz-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	border-bottom: 1px solid rgba(255, 255, 255, .08);
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
}

.metaportal_fn_mobnav .mob_bot {
	display: none;
	background: rgb(27, 18, 29);
	padding: 21px 20px;
	overflow: hidden;
}

.metaportal_fn_mobnav .mob_bot ul {
	margin: 0;
	list-style-type: none;
}

.metaportal_fn_mobnav .mob_bot li {
	padding: 8px 0;
	margin: 0;
}

.metaportal_fn_mobnav .mob_bot a {
	line-height: 27px;
}

.metaportal_fn_mobnav .social {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}


/* Social Icons */
#social {
	z-index: 100;
	position: fixed;
	left: 50px;
	top: 50%;
	transition: all .5s ease;
}

#social.hold {
	transform: translateX(-200px);
}

#social .social {
	-moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	position: absolute;
	width: max-content;
	-ms-align-items: center;
	align-items: center;
}

.social ul {
	margin: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.social li {
	margin: 0;
	font-family: var(--hff);
	font-size: 18px;
	text-decoration: none;
	color: var(--hc);
	font-weight: 500;
	letter-spacing: 0;
}

.social li:after {
	content: '/';
	margin: 0 6px;
}

.social li:last-child:after {
	content: '';
}

.social .title {
	margin: 0;
	padding: 0;
	margin-right: 10px;
	line-height: 50px;
}

.social a {
	font-family: var(--hff);
	font-size: 18px;
	text-decoration: none;
	color: var(--hc);
	font-weight: 500;
	letter-spacing: 0;
}

.social a:hover {
	color: var(--mc1);
}



/*Main Button*/
.metaportal_fn_protected input[type="submit"],
.metaportal_fn_button {
	display: block;
	width: fit-content;
	max-width: 100%;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: .5px;
	font-family: var(--hff);
	height: 50px;
	line-height: 50px;
	padding: 0 34px;
	position: relative;
	color: var(--hc);
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	background-color: transparent;
	outline: none;
	outline-color: transparent;
}

.metaportal_fn_button span {
	position: relative;
	z-index: 5;
}

.metaportal_fn_protected .submit {
	display: block;
	position: relative;
}

.metaportal_fn_protected .submit input {
	width: 100%;
	z-index: 5;
}

.metaportal_fn_button.full {
	width: 100%;
	height: 80px;
	line-height: 80px;
}

.metaportal_fn_protected .submit:hover:after,
.metaportal_fn_button:hover:after {
	animation-duration: 2s;
}

.metaportal_fn_protected .submit:hover,
.metaportal_fn_button:hover {
	color: #fff;
}

.metaportal_fn_protected .submit:after,
.metaportal_fn_button:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 6px;
	border: 3px solid transparent;
	background: -moz-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -webkit-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}

.metaportal_fn_protected .submit:before,
.metaportal_fn_button:before {
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 6px;
	box-shadow: 0px 5px 20px;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}












/* Totop Button */
.metaportal_fn_totop {
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	position: fixed;
	z-index: 10000;
	right: 30px;
	bottom: 30px;
	width: 50px;
	color: #bbb;
	font-size: 18px;
	letter-spacing: 0;
	font-family: var(--hff);
	font-weight: 500;
	opacity: 0;
	visibility: hidden;
	transform: translateX(100px);
	transition: all .5s ease;
}

.totop-active .metaportal_fn_totop {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.metaportal_fn_totop .icon {
	display: flex;
	width: 37px;
	height: 37px;
	border-radius: 100%;
	background-color: white;
	position: relative;
	margin-right: 30px;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_totop .fn__svg {
	position: absolute;
	display: block;
	z-index: 3;
	width: 20px;
	height: 20px;
	left: 50%;
	top: 50%;
	margin: -10px 0 0 -10px;
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.metaportal_fn_totop .icon:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .35;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	transition: all .3s ease;
}

.metaportal_fn_totop:hover .icon:after {
	opacity: .5;
}

.metaportal_fn_totop .totop_inner {
	-moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	transform: translateX(-50%) translateY(-50%) rotate(0deg);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	top: 50%;
	left: 50%;
	position: absolute;
	width: max-content;
	-ms-align-items: center;
	align-items: center;
}




/* Search Button */
.metaportal_fn_search {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #000;
	position: fixed;
	z-index: 5;
	bottom: 30px;
	left: 30px;
	color: #bbb;
	transition: all .5s ease;
}

.metaportal_fn_search.hold {
	transform: translateX(-300px);
}

.metaportal_fn_search:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_search:hover:after {
	opacity: .5;
}

.metaportal_fn_search .fn__svg {
	position: absolute;
	display: block;
	z-index: 3;
	width: 20px;
	height: 20px;
	left: 50%;
	top: 50%;
	margin: -10px 0 0 -10px;
}















/* Header */
.header {
	padding: 30px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 25;
	transition: all .3s ease;
	border-bottom: 1px solid transparent;
}

.header:after {
	transform: translateY(-105%);
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background: rgb(27, 18, 29);
	background: -moz-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	background: linear-gradient(90deg, rgba(27, 18, 29, 1) 0%, rgba(18, 15, 47, 1) 50%, rgba(27, 18, 29, 1) 100%);
	transition: all .3s ease;
}

.header.active:after {
	transform: translateY(0%);
}

.header.active {
	z-index: 60;
	padding: 20px 30px;
	border-bottom-color: rgba(255, 255, 255, .08);
}

.header .header_in {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
}

.header .nav {
	opacity: 0;
}

.header .trigger_logo {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.header .trigger {
	margin-right: 20px;
}

.trigger {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #000;
	z-index: 5;
	color: #bbb;
	position: relative;
	cursor: pointer;
}

.trigger:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.trigger.active:after,
.trigger:hover:after {
	opacity: .5;
}

.trigger.active span {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.trigger.active span:before {
	bottom: 0;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.trigger.active span:after {
	width: 0;
	transition: none;
}

.trigger span:before,
.trigger span:after,
.trigger span {
	display: block;
	height: 2px;
	position: absolute;
	background-color: #bbb;
	content: '';
	transition: all .3s ease;
}

.trigger span {
	top: 24px;
	width: 24px;
	left: 13px;
}

.trigger span:before {
	bottom: 6px;
	width: 24px;
	left: 0;
}

.trigger span:after {
	top: 6px;
	width: 16px;
	left: 0;
}

.header .nav ul {
	margin: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-left: -45px;
}

.header .nav li {
	margin: 0;
	margin-left: 45px;
}

.header .nav a {
	font-family: var(--hff);
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0;
	text-decoration: none;
}







.fn__maintitle {
	margin: 0;
	padding: 0;
	font-weight: 300;
	letter-spacing: .5px;
	font-size: 36px;
	position: relative;
	line-height: 1;
	z-index: 5;
}

.fn__maintitle.big {
	font-size: 60px;
	text-transform: uppercase;
}

.fn__maintitle[data-align="center"] {
	text-align: center;
}

.fn__maintitle[data-align="left"] {
	text-align: left;
}

.fn__maintitle[data-align="right"] {
	text-align: right;
}

.fn__maintitle:before {
	position: absolute;
	z-index: -1;
	top: 2px;
	left: 0;
	width: 100%;
	height: 100%;
	background: -moz-linear-gradient(var(--mc1), var(--mc2));
	background: -webkit-linear-gradient(var(--mc1), var(--mc2));
	background: linear-gradient(var(--mc1), var(--mc2));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	content: attr(data-text);
}

/* Since v4.0 */
.fn__maintitle.upper {
	text-transform: uppercase;
}

#home {
	overflow: hidden;
	padding-top: 179px;
	padding-bottom: 10px;
}



.fn_cs_slider {
	padding: 0 20px;
	overflow: hidden;
}

.fn_cs_slider .hidden_list {
	display: none;
}

.slider_nav {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 52px;
}

.slider_nav>* {
	margin-right: 100px;
}

.slider_nav>*:last-child {
	margin-right: 0;
}

.slider_nav:after {
	content: '';
	position: absolute;
	width: 40px;
	height: 1px;
	background-color: rgba(255, 255, 255, .5);
	left: 50%;
	margin-left: -20px;
	top: 50%;
	margin-top: -1px;
	margin-top: -0.5px;
}

.slider_nav a {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 10px 0;
}

.slider_nav a>* {
	margin-right: 10px;
}

.slider_nav a>*:last-child {
	margin-right: 0;
}

.slider_nav .circle {
	width: 12px;
	height: 12px;
	display: block;
	border: 2px solid rgba(255, 255, 255, .5);
	position: relative;
	border-radius: 100%;
	background-color: rgba(255, 255, 255, .05);
}

.slider_nav .icon {
	width: 34px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid rgba(255, 255, 255, .5);
	position: relative;
	border-radius: 100%;
	background-color: rgba(255, 255, 255, .05);
}

.slider_nav .fn__svg {
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	color: rgba(255, 255, 255, .5);
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
}

.slider_nav .prev .fn__svg {
	transform: rotate(90deg);
	margin-left: -8px;
}

.slider_nav .next .fn__svg {
	transform: rotate(-90deg);
	margin-left: -5px;
}

.slider_nav .icon:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.slider_nav a:hover .icon:after {
	opacity: .5;
}

.fn_cs_slider .slider_top>img {
	width: 33.3333% !important;
	min-width: 33.3333% !important;
	opacity: 0;
}

.fn_cs_slider .slider_top {
	padding: 59px 0 60px;
	position: relative;
}

.fn_cs_slider .slider_top ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.fn_cs_slider .slider_top li {
	width: 33.3333%;
	position: absolute;
	margin: 0;
	transform: translateX(100%) scale(0);
	transition: all 0s ease;
	opacity: 0;
	top: 65px;
	/*	visibility: hidden;*/
}

.fn_cs_slider .slider_top li.next2 {
	left: 100%;
	transform: translateX(100%) scale(0);
	transition: all 1s ease;
}

.fn_cs_slider .slider_top li.prev2 {
	left: 0;
	transform: translateX(-100%) scale(0);
	transition: all 1s ease;
}

.fn_cs_slider .slider_top li.prev,
.fn_cs_slider .slider_top li.next,
.fn_cs_slider .slider_top li.active {
	transform: translateX(0) scale(1);
	opacity: 1;
	transition: all 1s ease;
}

.fn_cs_slider .slider_top li.prev,
.fn_cs_slider .slider_top li.next {
	cursor: pointer;
}

.fn_cs_slider .slider_top li.prev {
	left: -40px;
}

.fn_cs_slider .slider_top li.next {
	left: 66.6666%;
	margin-left: 40px;
}

.fn_cs_slider .slider_top img {
	min-width: 100%;
	opacity: 0;
}

.fn_cs_slider .slider_top .item {
	perspective: 1000px;
	perspective-origin: 50% 50%;
}

.fn_cs_slider .slider_top .item_in {
	padding: 10px;
	border-radius: 20px;
	border: 1px solid rgba(255, 255, 255, .5);
	background-color: rgba(255, 255, 255, .05);
	transition: all 1s ease;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transform: scaleX(0.85) scaleY(0.85) rotateY(180deg);
}

.fn_cs_slider .metaportal_fn_videobutton {
	opacity: 0;
	visibility: hidden;
}

.fn_cs_slider .active .metaportal_fn_videobutton {
	opacity: 1;
	visibility: visible;
}

.fn_cs_slider .slider_top .img {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
}

.fn_cs_slider .slider_top .img:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all .3s ease;
}

.fn_cs_slider .slider_top .active .has_video .img:after {
	background-color: rgba(0, 0, 0, 0.3);
}

.fn_cs_slider .slider_top .item_in:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 20px;
	box-shadow: 0px 5px 20px;
}

.fn_cs_slider .slider_top .item_in:before {
	height: 15px;
	width: 60%;
	content: '';
	position: absolute;
	top: 100%;
	margin-top: 30px;
	background-color: #000;
	border-radius: 100%;
	left: 50%;
	transform: translateX(-50%);
	-webkit-filter: blur(15px);
	-o-filter: blur(15px);
	filter: blur(15px);
}

.fn_cs_slider .slider_top .active .item_in {
	transform: scaleX(1) scaleY(1) rotateY(0deg);
}

.fn_cs_slider .slider_top .prev2 .item_in,
.fn_cs_slider .slider_top .prev .item_in {
	transform: scaleX(1.05) scaleY(0.85) rotateY(-30deg);
}

.fn_cs_slider .slider_top .next2 .item_in,
.fn_cs_slider .slider_top .next .item_in {
	transform: scaleX(1.05) scaleY(0.85) rotateY(30deg);
}

.fn_cs_slider .slider_top .img {
	border-radius: 20px;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.fn_cs_slider .slider_top li.active {
	left: 33.3333%;
}

/* Since v3.0*/
@media(max-width: 768px) {
	.fn_cs_slider[data-responsive="on"] .slider_top li {
		width: 100%;
	}

	.fn_cs_slider[data-responsive="on"] .slider_top>img {
		width: 100% !important;
		min-width: 100% !important;
	}

	.fn_cs_slider[data-responsive="on"] .slider_top li.active {
		left: 0;
	}

	.fn_cs_slider[data-responsive="on"] .slider_top li.prev {
		left: -100%;
		margin-left: -100px;
	}

	.fn_cs_slider[data-responsive="on"] .slider_top li.next {
		left: 100%;
		margin-left: 100px;
	}
}



.fn_cs_desc {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	/* max-width: 1000px; */
	margin: 0 auto;
	text-align: center;
}

.fn_cs_desc p {
	margin: 0;
	color: rgba(255, 255, 255, 1);
	font-weight: 200;
	font-family: "AavartanFont";
	letter-spacing: 2px;
	text-shadow: 2px 2px 5px rgb(168, 10, 10);
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 41px;
	z-index: 2;
	/* padding: 0 4rem; */
}

@media(width<900px) {
	.fn_cs_desc p {
		font-size: 15px;
		line-height: 20px;
		padding: 0;
	}
}

#fun_facts {
	border-top: 1px solid rgba(255, 255, 255, .08);
	border-bottom: 1px solid rgba(255, 255, 255, .08);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
	background-color: rgba(0, 0, 0, 0.15);
	padding: 132px 0 87px;
}

.fn_cs_counter_list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	margin-left: -140px;
}

.fn_cs_counter_list li {
	padding-left: 140px;
	margin: 0;
	width: 25%;
	margin-bottom: 40px;
}

.fn_cs_counter_list li:nth-of-type(4n) .divider {
	display: none;
}

.fn_cs_counter_list .item {
	position: relative;
	width: 100%;
	text-align: center;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	padding: 0 10px;
}

.fn_cs_counter_list .item h3 {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 6px;
}

.fn_cs_counter_list .item p {
	margin: 0;
}

.fn_cs_counter_list .divider {
	position: absolute;
	left: 100%;
	top: 25px;
	width: 140px;
	height: 2px;
	background-color: rgba(255, 255, 255, .5);
}

.fn_cs_counter_list .divider:after,
.fn_cs_counter_list .divider:before {
	width: 16px;
	content: '';
	position: absolute;
	height: 2px;
	background-color: rgba(255, 255, 255, .5);
	transform: rotate(-65deg);
}

.fn_cs_counter_list .divider:before {
	left: 50%;
	margin-left: -11px;
}

.fn_cs_counter_list .divider:after {
	left: 50%;
	margin-left: -5px;
}


.fn__gradient_title {
	width: fit-content;
	max-width: 100%;
	margin: 0;
	padding: 0;
	font-size: 60px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 1;
	background: -moz-linear-gradient(90deg, var(--mc1), var(--mc2));
	background: -webkit-linear-gradient(90deg, var(--mc1), var(--mc2));
	background: linear-gradient(to right, var(--mc1), var(--mc2));
	-webkit-background-clip: text;
	-webkit-text-stroke: 4px transparent;
	color: #160f1a;
	filter: drop-shadow(0 5px 20px rgba(204, 0, 255, .15));
}






.fn_cs_about {
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-bottom: 150px;
}

.fn_cs_about .left_part {
	width: 57%;
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	-ms-align-items: center;
	align-items: center;
	padding: 110px 0;
}

.fn_cs_about .left_part .img_in {
	background-size: cover;
	border-radius: 10px;
	background-position: center center;
	background-repeat: no-repeat;
}

.fn_cs_about .left_part .img {
	width: 542px;
	max-width: 100%;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, .5);
	background-color: rgba(255, 255, 255, .05);
	position: relative;
	z-index: 5;
}

.fn_cs_about .left_part .img img {
	min-width: 100%;
	opacity: 0;
}

.fn_cs_about .left_part .bg_overlay {
	right: 130px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}

.fn_cs_about .left_part .bg_color {
	background-color: rgba(0, 0, 0, 0.7);
}

.fn_cs_about .right_part {
	width: 43%;
	padding: 110px 100px;
}

.fn_cs_about .right_in {
	max-width: 500px;
	margin: 0 auto;
}

.fn_cs_about .fn__maintitle {
	margin-bottom: 15px;
}

.fn_cs_about .fn_cs_divider {
	margin-bottom: 25px;
}

.desc p {
	margin-bottom: 27px;
}

.desc p:last-child {
	margin-bottom: 0;
}

.fn_cs_about .desc {
	margin-bottom: 32px;
}





.fn_cs_divider {
	height: 16px;
	position: relative;
}

.fn_cs_divider .divider {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -1px;
	width: 140px;
	height: 2px;
	background-color: rgba(255, 255, 255, .5);
}

.fn_cs_divider .divider:after,
.fn_cs_divider .divider:before {
	width: 16px;
	content: '';
	position: absolute;
	height: 2px;
	background-color: rgba(255, 255, 255, .5);
	transform: rotate(-65deg);
}

.fn_cs_divider .divider:before {
	left: -3px;
}

.fn_cs_divider .divider:after {
	left: 3px;
}




.fn_cs_mint {
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.fn_cs_mint .left_part {
	width: 40%;
	padding-right: 100px;
}

.fn_cs_mint .right_part {
	width: 60%;
}

.fn_cs_mint .fn__maintitle {
	margin-bottom: 15px;
}

.fn_cs_mint .fn_cs_divider {
	margin-bottom: 25px;
}

.fn_cs_mint .desc {
	margin-bottom: 32px;
}









.fn_cs_video {
	position: relative;
}

.fn_cs_video img {
	min-height: 240px;
	min-width: 100%;
	border-radius: 10px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}

a.metaportal_fn_videobutton,
.fn_cs_video a {
	position: absolute;
	display: block;
	left: 50%;
	top: 50%;
	margin: -60px 0 0 -60px;
	z-index: 25;
}

a.metaportal_fn_videobutton .fn__svg,
.fn_cs_video a .fn__svg {
	width: 120px;
	height: 120px;
	display: block;
	transition: all 1s ease;
	border-radius: 50%;
}

a.metaportal_fn_videobutton .stroke-solid,
.fn_cs_video a .stroke-solid {
	strokeWidth: 6px;
	stroke: rgba(255, 255, 255, .5);
}

a.metaportal_fn_videobutton .icon,
.fn_cs_video a .icon {
	color: #fff;
}

a.metaportal_fn_videobutton:hover .stroke-solid,
.fn_cs_video a:hover .stroke-solid {
	opacity: 1;
	stroke-dashoffset: 300;
}

a.metaportal_fn_videobutton:hover .icon,
.fn_cs_video a:hover .icon {
	transform: scale(.8);
}

a.metaportal_fn_videobutton .stroke-solid,
.fn_cs_video a .stroke-solid {
	stroke-dashoffset: 0;
	stroke-dashArray: 300;
	strokeWidth: 4px;
	transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

a.metaportal_fn_videobutton .icon,
.fn_cs_video a .icon {
	transform: scale(.7);
	transform-origin: 50% 50%;
	transition: transform 200ms ease-out;
}

a.metaportal_fn_videobutton:hover .fn__svg,
.fn_cs_video a:hover .fn__svg {
	background-color: rgba(255, 255, 255, .05);
}

/* since v4.0 */
.fn_cs_video.bg {
	min-height: 700px;
}

.fn_cs_video.bg a {
	margin: -125px 0 0 -125px;
}

.fn_cs_video.bg a .fn__svg {
	width: 250px;
	height: 250px;
}

.fn_cs_video.bg a .stroke-solid {
	strokeWidth: 2px;
}

.fn_cs_video.bg a .icon {
	transform: scale(.3);
}

.fn_cs_video.bg a:hover .icon {
	transform: scale(.4);
}




#about {
	padding: 150px 0;
}













.fn_cs_section_divider {
	width: 100%;
	position: relative;
	height: 12px;
}

.fn_cs_section_divider .divider {
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin: 0 auto;
	width: fit-content;
	max-width: 100%;
}

.fn_cs_section_divider .divider>* {
	margin-right: 20px;
}

.fn_cs_section_divider .divider>*:last-child {
	margin-right: 0;
}

.fn_cs_section_divider .divider:before,
.fn_cs_section_divider .divider:after {
	content: '';
	position: absolute;
	background-color: rgba(255, 255, 255, .15);
	height: 1px;
	top: 5px;
	width: 5000px;
}

.fn_cs_section_divider .divider:before {
	right: 100%;
	margin-right: 20px;
}

.fn_cs_section_divider .divider:after {
	left: 100%;
	margin-left: 20px;
}

.fn_cs_section_divider span {
	height: 12px;
	position: relative;
	display: block;
	border: 2px solid rgba(255, 255, 255, .5);
	border-radius: 12px;
	background-color: rgba(255, 255, 255, .05);
}

.fn_cs_section_divider span:after {
	right: -2px;
	bottom: -2px;
	top: -2px;
	left: -2px;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 12px;
	box-shadow: 0px 5px 20px;
}

.fn_cs_section_divider .short {
	width: 12px;
}

.fn_cs_section_divider .long {
	width: 200px;
}



@media (min-width:1325px) and (max-width:1375px) {
	.hcap {
		position: relative;
		left: -30px;
		transform: scale(0.9);
	}
}

@media (min-width:1080px) and (max-width:1324px) {
	.hcap {
		position: relative;
		transform: scale(0.8);
		left: -60px;
	}
}

@media (min-width:900px) and (max-width:1080px) {
	.hcap {
		position: relative;
		transform: scale(0.68);
		left: -60px;
	}
}


@media(min-width:705px) and (max-width:900px) {
	.hcap {
		position: relative;
		left: -10px;
	}
}

@media(min-width:600px) and (max-width: 704px) {
	.hcap {
		position: relative;
		left: -50px;
		transform: scale(0.73);
	}
}

@media(max-width:365px) {
	.hcap {
		position: relative;
		left: -24px;
		transform: scale(0.90);
	}
}

@media (max-width:320px) {
	.hcap {
		position: relative;
		left: -45px;
		transform: scale(0.85);
	}
}

@media (max-width:297px) {
	.hcap {
		position: relative;
		left: -40px;
		transform: scale(0.80);
	}
}

@media (max-width:285px) {
	.hcap {
		position: relative;
		left: -40px;
		transform: scale(0.75);
	}
}

@media (max-width:270px) {
	.hcap {
		position: relative;
		left: -48px;
		transform: scale(0.70);
	}
}


#collection {
	overflow: hidden;
}

#collection .fn__maintitle {
	margin-bottom: 140px;
}

.fn_cs_collection {
	margin-bottom: 10px;
}

.fn_cs_collection .item {
	padding-left: 20px;
	margin-bottom: 40px;
}

.fn_cs_collection img {
	min-width: 100%;
	opacity: 0;
}

.fn_cs_collection .abs_img {
	transition: all 0.8s ease;
	overflow: hidden;
	border-radius: 10px;
}

.fn_cs_collection .ready .abs_img {
	transform: rotateY(-30deg) translateZ(0);
}

.fn_cs_collection .img {
	position: relative;
	perspective: 600px;
	/* perspective-origin: 50% 50%; */
}

.fn_cs_collection .item_in {
	padding: 10px;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, .5);
	background-color: rgba(33, 33, 33, .15);
	position: relative;
	backdrop-filter: blur(5px);
}

.fn_cs_collection .item_in:after {
	right: -1px;
	bottom: -1px;
	top: -1px;
	left: -1px;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 10px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.fn_cs_collection .item:hover .item_in:after {
	opacity: .5;
}

.fn_cs_collection .collection_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: flex-end;
	align-items: flex-end;
	margin-left: -40px;
	flex-wrap: wrap;
	/* padding-right: 40px; */
}

.fn_cs_collection .collection_top .item {
	width: 31.2%;
}

.fn_cs_collection .collection_top .item:nth-child(2),
.fn_cs_collection .collection_top .item:nth-child(4) {
	width: 18.8%;
}

.fn_cs_collection .collection_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: flex-start;
	align-items: flex-start;
	margin-left: -40px;
	flex-wrap: wrap;
	padding-left: 40px;
}

.fn_cs_collection .collection_bottom .item {
	width: 31.2%;
}

.fn_cs_collection .collection_bottom .item:nth-child(1),
.fn_cs_collection .collection_bottom .item:nth-child(3) {
	width: 18.8%;
}

/* Since v4.0 */
.fn_cs_collection.modern .collection_top {
	padding-right: 0;
}

.fn_cs_collection.modern .collection_bottom {
	padding-left: 0;
}

.fn_cs_collection.modern .collection_bottom .item,
.fn_cs_collection.modern .collection_top .item {
	width: 18.8%;
}

.fn_cs_collection.modern .collection_bottom .item:nth-child(2),
.fn_cs_collection.modern .collection_bottom .item:nth-child(3),
.fn_cs_collection.modern .collection_top .item:nth-child(2),
.fn_cs_collection.modern .collection_top .item:nth-child(3) {
	width: 31.2%;
}


#collection {
	padding: 0;
}













#news {
	padding: 144px 0 150px 0;
}

#news .fn__maintitle {
	margin-bottom: 140px;
}






.fn_cs_news .bottom_part {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	column-gap: 40px;
	-ms-align-items: center;
	align-items: center;
}

.fn_cs_news .bottom_part .left_bot,
.fn_cs_news .bottom_part .right_bot {
	width: 50%;
}

.fn_cs_news .bottom_part p {
	margin: 0;
	padding: 0 40px
}

.fn_cs_news .news_part {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	column-gap: 40px;
	margin-bottom: 40px;
}

.fn_cs_news .right_items {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	gap: 40px;
	width: 50%;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

.fn_cs_news .left_items {
	width: 100%;
}

.fn_cs_news .left_items .blog__item {
	height: 100%;
}

.fn_cs_news .right_items .blog__item {
	height: 33.3333%;
}

.blog__item {
	margin-top: 20px;
	padding: 0px;
	border: 10px solid rgb(0, 0, 0);
	border-radius: 10px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}

.blog__item .image {
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 27px;
}

.blog__item .counter {
	margin-bottom: 15px;
}

.blog__item .counter .cc {
	width: 50px;
	height: 50px;
	display: block;
	position: relative;
	text-align: center;
	line-height: 50px;
	letter-spacing: 2px;
	font-size: 16px;
	font-family: var(--hff);
}

.blog__item .counter .cc span {
	margin-right: -2px;
	margin-top: 1px;
}

.blog__item .counter .cc:before {
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
}

.blog__item .counter .cc:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 100%;
	border: 3px solid transparent;
	background: -moz-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -webkit-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -o-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: linear-gradient(to top right, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}

.blog__item .meta {
	margin-bottom: 7px;
}

.blog__item .meta p {
	margin: 0;
}

.blog__item .title {
	margin: 10px;
}

.blog__item .title h3 {
	margin: 0;
	font-weight: 300;
	font-size: 30px;
	letter-spacing: 0;
}

.blog__item .read_more a {
	text-decoration: none;
	font-family: var(--hff);
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: .5px;
	color: var(--bc);
	padding-left: 20px;
	position: relative;
}

/* .blog__item .read_more a:after{
	left: 0;
	width: 100px;
	height: 1px;
	content: '';
	position: absolute;
	top: 50%;
	background-color: #8d898e;
	transition: all .3s ease;
} */
.blog__item .read_more span {
	opacity: 0;
	transition: opacity .3s ease, transform .3s ease;
	display: inline-block;
}

.blog__item:hover .read_more a:after {
	width: 50px;
}

.blog__item:hover .read_more span {
	opacity: 1;
	transform: translateX(-30px);
}

.blog__item .read_more a:hover {
	color: var(--mc1);
}






#footer {
	padding: 34px 0 32px;
	border-top: 1px solid rgba(255, 255, 255, .08);
	background-color: rgba(10, 10, 10, 0.15);
}

.footer {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-webkit-column-gap: 40px;
	-moz-column-gap: 40px;
	-o-column-gap: 40px;
	column-gap: 40px;
	-ms-align-items: center;
	align-items: center;
}

.footer p {
	margin: 0;
	font-size: 16px;
	font-family: var(--hff);
	letter-spacing: 0;
}

.footer p a {
	color: #fff;
	text-decoration: none;
}

.footer p a:hover {
	color: var(--mc1);
}

.footer ul {
	margin: 0;
	list-style-type: none;
	margin-left: -30px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
}

.footer li {
	margin: 0;
	margin-left: 30px;
}

.footer li a {
	font-size: 16px;
	font-family: var(--hff);
	letter-spacing: 0;
	text-decoration: none;
}

#contact {
	padding: 0 15rem 4rem 15rem;
}

@media(width<700px) {
	#contact {
		padding: 0;
		padding-bottom: 4rem !important;
	}
}

.ptitle {
	color: rgba(233, 215, 15, 0.838);
	margin: 50px 0px 50px 0px;
	position: relative;
	text-align: center;
	z-index: 3;
	font-family: "Minion";
  
	/* Add padding to give some space around the text */
	padding: 10px 20px;
  
	/* Create a semi-transparent background */
	background-color: rgba(0, 0, 0, 0.5);
  
	/* Add backdrop filter for blur effect */
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px); /* For Safari */
  
	/* Rounded corners for a softer look */
	border-radius: 8px;
  
	/* Add a subtle text shadow for better contrast */
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  /* Update the .fn_cs_contact_info class */
  .fn_cs_contact_info {
	padding: 140px 0 32px;
	position: relative;
	background-color: rgba(0, 0, 0, 0.7); /*This line is kept from the original code as it's not replaced in the update*/
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Add a pseudo-element for the blurred background */
  .fn_cs_contact_info::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: -1;
	border-radius: 10px;
  }
  
  /* Update the .fn_cs_contact_info ul class */
  .fn_cs_contact_info ul {
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	margin-left: -150px;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 5px;
  }
  
  /* Update the .fn_cs_contact_info li class */
  .fn_cs_contact_info li {
	padding-left: 150px;
	width: 33.333%;
	margin-bottom: 40px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Update the .fn_cs_contact_info h4 class */
  .fn_cs_contact_info h4 {
	margin: 0;
	margin-bottom: 9px;
	font-weight: 500;
	color: var(--mc1);
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: .5px;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  /* Update the .fn_cs_contact_info h3 class */
  .fn_cs_contact_info h3 {
	margin: 0;
	margin-bottom: 39px;
	font-weight: 300;
	letter-spacing: 0;
	font-size: 30px;
	color: #fff;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Add styles for better text visibility */
  .fn_cs_contact_info p,
  .fn_cs_contact_info h3,
  .fn_cs_contact_info h4 {
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  /* Update the .fn_cs_contact_form class */
  .fn_cs_contact_form {
	background-color: rgba(0, 0, 0, 0.7);
	padding: 40px;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Update the input and textarea styles */
  .fn_cs_contact_form input[type="text"],
  .fn_cs_contact_form textarea {
	display: block;
	width: 100%;
	min-width: 100%;
	border: 2px solid rgba(255, 255, 255, 0.15);
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.3) !important;
	outline: none;
	color: #fff;
  }
  
  .fn_cs_contact_form textarea:focus,
  .fn_cs_contact_form input[type="text"]:focus {
	border-color: var(--mc1);
	box-shadow: 0 0 10px rgba(var(--mc1-rgb), 0.5);
  }
  
  /* Update the form list item styles */
  .fn_cs_contact_form li {
	width: 50%;
	padding-left: 40px;
	margin-bottom: 40px;
	position: relative;
  }
  
  .fn_cs_contact_form li:before {
	content: "";
	position: absolute;
	left: 40px;
	top: 0;
	bottom: 0;
	width: 2px;
	/* background-color: var(--mc1); */
	opacity: 0.5;
  }
  
  .fn_cs_contact_form li.full:before {
	display: none;
  }
  
  
.empty_notice,
.returnmessage {
	padding: 20px;
	width: 100%;
	display: none;
	color: #eee;
	margin-top: 20px;
	position: relative;
}

.empty_notice:after,
.returnmessage:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 10px;
	border: 3px solid transparent;
	background: -moz-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -webkit-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -o-linear-gradient(bottom left, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: linear-gradient(to top right, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}







.fn__checkbox {
	display: block;
	position: relative;
	padding-left: 37px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 37px;
}

.fn__checkbox .fn__svg {
	opacity: 0;
	width: 14px;
	height: 14px;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -7px 0 0 -7px;
	color: var(--mc1);
}

.fn__checkbox p {
	margin: 0;
}

.fn__checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.fn__checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	border: 2px solid rgba(255, 255, 255, .15);
	border-radius: 5px;
}

.fn__checkbox input:checked~.fn__checkmark .fn__svg {
	opacity: 1;
}











.metaportal_fn_search_closer {
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	transition: all .5s ease;
	cursor: pointer;
	transform: translateY(102%);
}

.metaportal_fn_search_closer.active {
	transform: translateY(0);
}

.metaportal_fn_searchbox.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.metaportal_fn_searchbox {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-102%);
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	background-color: #130f15;
	padding: 100px 0;
	transition: all .5s ease;
}

.metaportal_fn_searchbox .searchbox {
	position: relative;
}

.metaportal_fn_searchbox input {
	display: block;
	width: 100%;
	border: none;
	background-color: transparent !important;
	padding: 0 70px 0 30px;
	height: 120px;
	border-radius: 0;
	font-size: 48px;
	letter-spacing: 0;
	font-weight: 300;
	font-family: var(--hff);
	color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.metaportal_fn_searchbox a {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100px;
	height: 120px;
	color: #bbb;
}

.metaportal_fn_searchbox a .fn__svg {
	width: 40px;
	height: 40px;
	display: block;
}








.metaportal_fn_leftnav.ready,
.metaportal_fn_leftnav_closer.ready {
	display: none;
}

.metaportal_fn_leftnav_closer {
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	transition: all .5s ease;
	cursor: pointer;
	transform: translateX(102%);
}

.metaportal_fn_leftnav.active,
.metaportal_fn_leftnav_closer.active {
	transform: translateX(0);
}

.metaportal_fn_leftnav {
	transform: translateX(-102%);
	width: 400px;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #130f15;
	z-index: 9999;
	padding: 130px 40px;
	transition: all .5s ease;
	overflow-y: auto;
}

.metaportal_fn_leftnav .navbox {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: center;
	position: relative;
	z-index: 15;
}

.metaportal_fn_leftnav .nav_holder {
	padding: 73px 0 64px;
	overflow: hidden;
	position: relative;
}

.metaportal_fn_leftnav .nav_holder .icon {
	display: none;
}

.metaportal_fn_leftnav .nav_holder ul {
	list-style-type: none;
	margin: 0;
	text-align: center;
	transition: all .3s ease;
}

.metaportal_fn_leftnav .nav_holder .sub-menu {
	margin: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background-color: #130f15;
	left: 100%;
	opacity: 0;
}

.metaportal_fn_leftnav .nav_holder .active~.sub-menu {
	z-index: 5;
	opacity: 1;
}

.metaportal_fn_leftnav .nav_holder a {
	font-size: 18px;
	letter-spacing: 0;
	line-height: 30px;
	font-family: var(--hff);
	text-decoration: none;
	display: block;
	padding: 0 17px;
}

.metaportal_fn_leftnav .nav_holder .fn__svg {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #aaa;
	width: 12px;
	height: 12px;
	position: absolute;
	left: 100%;
	margin-left: 5px;
	top: 50%;
	margin-top: -6px;
}

.metaportal_fn_leftnav .nav_holder .prev {
	font-size: 18px;
}

.metaportal_fn_leftnav .nav_holder .prev .fn__svg {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	right: 100%;
	margin-right: 5px;
	left: auto;
	margin-left: 0;
	margin-top: -7px;
}

.metaportal_fn_leftnav .nav_holder li {
	margin-bottom: 13px;
}

.metaportal_fn_leftnav .nav_holder li:last-child {
	margin-bottom: 0;
}

.metaportal_fn_leftnav .copyright {
	text-align: center;
	margin-bottom: 17px;
}

.metaportal_fn_leftnav .copyright p {
	max-width: 250px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0;
	font-family: var(--hff);
	color: #888;
}

.metaportal_fn_leftnav .copyright a {
	color: #ddd;
	text-decoration: none;
}

.metaportal_fn_leftnav .copyright a:hover {
	color: var(--mc1);
}

.metaportal_fn_leftnav .social_icons ul {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	list-style-type: none;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	margin-left: -30px;
}

.metaportal_fn_leftnav .social_icons li {
	margin: 5px 0 5px 30px;
}

.metaportal_fn_leftnav .social_icons a {
	text-decoration: none;
	color: #777;
}

.metaportal_fn_leftnav .social_icons a img:hover {
	scale: 1.1;
}

.metaportal_fn_leftnav .social_icons .fn__svg {
	width: 20px;
	height: auto;
	display: block;
}

.metaportal_fn_leftnav {
	scrollbar-width: thin;
	scrollbar-color: #999 #fff;
}

.metaportal_fn_leftnav::-webkit-scrollbar {
	width: 4px;
}

.metaportal_fn_leftnav:-webkit-scrollbar-track {
	background: #444;
}

.metaportal_fn_leftnav::-webkit-scrollbar-thumb {
	background-color: var(--mc1);
}





.metaportal_fn_wallet_closer.ready,
.metaportal_fn_walletbox.ready {
	display: none;
}

.metaportal_fn_wallet_closer {
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	transition: all .5s ease;
	cursor: pointer;
	transform: translateX(-102%);
}

.metaportal_fn_wallet_closer.active {
	transform: translateX(0);
}

.metaportal_fn_walletbox {
	transform: translateX(102%);
	width: 400px;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #130f15;
	z-index: 9999;
	padding: 100px 40px;
	transition: all .5s ease;
	overflow-y: auto;
}

.metaportal_fn_walletbox {
	scrollbar-width: thin;
	scrollbar-color: #999 #fff;
}

.metaportal_fn_walletbox::-webkit-scrollbar {
	width: 4px;
}

.metaportal_fn_walletbox:-webkit-scrollbar-track {
	background: #444;
}

.metaportal_fn_walletbox::-webkit-scrollbar-thumb {
	background-color: var(--mc1);
}

.metaportal_fn_walletbox.active {
	transform: translateX(0);
}

.metaportal_fn_walletbox .walletbox {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: center;
}

.metaportal_fn_walletbox .title_holder {
	text-align: center;
	margin-bottom: 23px;
}

.metaportal_fn_walletbox .title_holder h3 {
	margin: 0;
	font-size: 22px;
	letter-spacing: .5px;
	text-transform: uppercase;
	margin-bottom: 18px;
}

.metaportal_fn_walletbox .title_holder p {
	margin: 0;
	font-size: 16px;
	letter-spacing: 0px;
	line-height: 1.5;
	color: #888;
}

.metaportal_fn_items {
	margin: 0;
	list-style-type: none;
}

.metaportal_fn_items li {
	margin-bottom: 20px;
}

.metaportal_fn_items li:last-child {
	margin-bottom: 0;
}

.metaportal_fn_items .item {
	width: 200px;
	max-width: 100%;
	margin: 0 auto;
	padding: 30px 23px 30px;
	background-color: #130f15;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	position: relative;
}

.metaportal_fn_items .item:hover:after {
	animation-duration: 2s;
}

.metaportal_fn_items .item:hover .icon:after {
	opacity: .5;
}

.metaportal_fn_items a {
	position: absolute;
	display: block;
	text-decoration: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.metaportal_fn_items .item:before {
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 5px;
	box-shadow: 0px 5px 20px;
}

.metaportal_fn_items .item:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 5px;
	border: 3px solid transparent;
	background: -moz-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -webkit-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}

.metaportal_fn_items .icon {
	width: 100px;
	height: 100px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	background-color: #000;
	border-radius: 100%;
	position: relative;
	margin-bottom: 9px;
	overflow: hidden;
}

.metaportal_fn_items .icon img {
	z-index: 5;
	position: relative;
}

.metaportal_fn_items .fn__svg {
	width: 22px;
	height: 22px;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -11px 0 0 -11px;
}

.metaportal_fn_items .icon:after {
	content: '';
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	opacity: .15;
	left: 0;
	top: 0;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_items .text {
	font-size: 16px;
	font-family: var(--hff);
	letter-spacing: .5px;
	text-transform: uppercase;
	color: var(--hc);
}



.creative_link {
	background-image: -moz-linear-gradient(90deg, var(--mc1), var(--mc1) 50%, #ddd 50%);
	background-image: -webkit-linear-gradient(90deg, var(--mc1), var(--mc1) 50%, #ddd 50%);
	background-image: linear-gradient(to right, var(--mc1), var(--mc1) 50%, #ddd 50%);
	background-size: 200% 100%;
	background-position: -100%;
	display: inline-block;
	position: relative;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: all 0.3s ease-in-out;
}

.creative_link:before {
	content: "";
	background: var(--mc1);
	display: block;
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 0;
	height: 2px;
	transition: all 0.3s ease-in-out;
}

.creative_link:hover {
	background-position: 0;
}

.creative_link:hover:before {
	width: 100%;
}


.fn__closer {
	display: none;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #000;
	color: #bbb;
	position: fixed;
	z-index: 10;
	right: 40px;
	top: 70px;
	cursor: pointer;
	text-decoration: none;
}

.fn__closer:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.fn__closer:hover:after {
	opacity: .5;
}

.fn__closer span:after {
	display: block;
	height: 2px;
	position: absolute;
	background-color: #bbb;
	content: '';
	width: 24px;
	transform: rotate(45deg);
	left: 13px;
	top: 24px;
}

.fn__closer span:before {
	display: block;
	height: 2px;
	position: absolute;
	background-color: #bbb;
	content: '';
	width: 24px;
	transform: rotate(-45deg);
	left: 13px;
	top: 24px;
}








/* Page Title */
.metaportal_fn_pagetitle {
	padding: 0;
	text-align: center;
}

.metaportal_fn_pagetitle h3 {
	margin-bottom: 27px;
}

.metaportal_fn_pagetitle p a {
	color: var(--bc);
	text-decoration: none;
}

.metaportal_fn_pagetitle p a:hover {
	color: rgb(58, 211, 58);
}

.metaportal_fn_pagetitle p {
	margin: 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_pagetitle .divider {
	margin: 0 10px;
	color: rgb(58, 211, 58);
}

.metaportal_fn_pagetitle .text{
	color: rgb(58, 211, 58);
}



/* Blog List */
.metaportal_fn_bloglist .bloglist ul {
	margin: 0;
	padding: 100px 0 20px;
	margin-left: -40px;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.metaportal_fn_bloglist .bloglist li {
	margin: 0;
	width: 50%;
	padding-left: 40px;
	margin-bottom: 40px;
}



/* Pagination */
.metaportal_fn_pagination {
	margin-bottom: 140px;
}

.metaportal_fn_pagination ul {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
	margin: 0;
	list-style-type: none;
	margin-left: -10px;
}

.metaportal_fn_pagination li {
	margin: 0 0 10px 10px;
}

.metaportal_fn_pagination span,
.metaportal_fn_pagination a {
	display: block;
	text-decoration: none;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-family: var(--hff);
	font-size: 16px;
	font-weight: 500;
	background-color: #000;
	border-radius: 100%;
	position: relative;
}

.metaportal_fn_pagination a:after,
.metaportal_fn_pagination span:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_pagination span {
	color: var(--bc);
}

.metaportal_fn_pagination a {
	color: #ddd;
}

.metaportal_fn_pagination span:hover:after,
.metaportal_fn_pagination a:hover:after {
	opacity: .5;
}



/* Mint Page */
.metaportal_fn_mint_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	padding: 180px 0 80px;
}

.metaportal_fn_mint_top .mint_left:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 10px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_mint_top .mint_left {
	width: 52%;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, .5);
	background-color: rgba(255, 255, 255, .05);
	position: relative;
	z-index: 5;
}

.metaportal_fn_mint_top .mint_left .img {
	border-radius: 10px;
	overflow: hidden;
}

.metaportal_fn_mint_top .mint_left .img,
.metaportal_fn_mint_top .mint_left .img_in {
	height: 100%;
}

.metaportal_fn_mint_top .mint_right {
	width: 48%;
	padding-left: 60px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: center;
}

.metaportal_fn_mint_top .mint_left img {
	min-width: 100%;
	opacity: 0;
}

.metaportal_fn_mint_top .mint_left .img_in {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.metaportal_fn_mint_top .view_on ul {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	list-style-type: none;
	margin-left: -10px;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_mint_top .view_on li {
	margin: 0 0 10px 10px;
}

.metaportal_fn_mint_top .view_on li:nth-child(2) {
	margin-left: 20px;
}

.metaportal_fn_mint_top .view_on span {
	font-family: var(--hff);
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: #ddd;
}

.metaportal_fn_mint_top .view_on a {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #000;
	color: #ddd;
	position: relative;
}

.metaportal_fn_mint_top .view_on a:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_mint_top .view_on .fn__svg {
	width: 22px;
	height: 22px;
}

.metaportal_fn_mint_top .view_on a:hover:after {
	opacity: .5;
}


.metaportal_fn_mint_top .fn__maintitle {
	margin-bottom: 30px;
}

.metaportal_fn_mint_top .desc {
	margin-bottom: 32px;
}

/* Share */
.metaportal_fn_share {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	-ms-align-items: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 11px;
}

.metaportal_fn_share .label {
	margin: 0;
	padding: 0;
	margin-right: 20px;
	color: var(--bc);
	font-family: var(--bff);
	font-size: 18px;
}

.metaportal_fn_share ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
	margin-left: -25px;
}

.metaportal_fn_share li {
	margin: 5px 0px 5px 25px;
	padding: 0;
}

.metaportal_fn_share a {
	color: var(--bc);
	font-size: 20px;
	text-decoration: none;
	text-align: center;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_share a:hover {
	color: #fff;
}

/* Breadcrumbs */
.metaportal_fn_breadcrumbs {
	margin-bottom: 19px;
}

.metaportal_fn_breadcrumbs p {
	margin: 0;
	padding: 0;
	font-size: 18px;
	color: var(--bc);
}

.metaportal_fn_breadcrumbs a {
	color: var(--bc);
	text-decoration: none;
}

.metaportal_fn_breadcrumbs .separator {
	margin: 0 9px;
}

.metaportal_fn_breadcrumbs a:hover {
	color: var(--mc1);
}


/* Mint Box */
.qnt {
	margin: 0;
	padding: 0;
	font-size: 24px;
	letter-spacing: 0;
	font-weight: 400;
	color: #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.qnt .increase,
.qnt .decrease {
	cursor: pointer;
	color: #bbb;
	transition: all .3s ease;
}

.qnt .increase:hover,
.qnt .decrease:hover {
	color: var(--mc1);
}

.metaportal_fn_mintbox {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	border-radius: 15px;
	border: 1px solid rgba(255, 255, 255, .15);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
	margin-bottom: 73px;
}

.metaportal_fn_mintbox .mint_left {
	width: 40%;
	padding: 115px 50px 50px;
	position: relative;
	border-radius: 15px 0 0 15px;
}

.metaportal_fn_mintbox .mint_right {
	width: 60%;
	padding: 60px 50px 25px 50px;
	border-radius: 0 15px 15px 0;
	background-color: rgba(255, 255, 255, 0.066);
	border-left: 1px solid rgba(255, 255, 255, .15);
}

.metaportal_fn_mintbox .mint_title {
	position: absolute;
	top: 30px;
	left: -9px;
}

.metaportal_fn_mintbox .mint_title span {
	position: relative;
	font-size: 16px;
	text-transform: uppercase;
	color: white;
	letter-spacing: .5px;
	font-family: var(--hff);
	background-color: #964ec3ce;
	padding-right: 40px;
	padding-left: 59px;
	line-height: 48px;
	font-weight: 600;
	display: inline-block;
	white-space: nowrap;
}

.metaportal_fn_mintbox .mint_title span:before {
	content: '';
	position: absolute;
	left: 100%;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 48px 0 0 20px;
	border-color: transparent transparent transparent #964ec3ce;
}

.metaportal_fn_mintbox .mint_list {
	margin-bottom: 5px;
}

.metaportal_fn_mintbox .mint_list label p {
	/* color: white; */
	font-size: 14px;
	padding-top: 1rem;
}

.metaportal_fn_mintbox .mint_list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -40px;
}

.metaportal_fn_mintbox .mint_list li {
	width: 100%;
	padding-left: 40px;
	margin-bottom: 30px;
}

.metaportal_fn_mintbox .mint_list li input {
	color: white !important;
	width: 100% !important;
	border: 1.5px solid rgba(255, 255, 255, 0.762) !important;
}

.metaportal_fn_mintbox .mint_list li input::placeholder {
	color: rgba(255, 255, 255, 0.762) !important;
}

.metaportal_fn_mintbox .mint_list li li {
	cursor: pointer;
	width: 50%;
	font-size: 14px;
	margin: 0;
	color: rgba(255, 255, 255, 0.762);
}

.metaportal_fn_mintbox .mint_list li li:hover {
	cursor: pointer;
	color: white;
}


.metaportal_fn_mintbox .mint_list .item {
	height: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	position: relative;
	padding-bottom: 10px;
}

.metaportal_fn_mintbox .mint_list .item:after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	bottom: 0;
	height: 1px;
	background-color: var(--mc1);
	transition: all .3s ease;
}

.metaportal_fn_mintbox .mint_list .item:hover:after {
	width: 100%;
}

.metaportal_fn_mintbox .mint_list h4 {
	font-size: 16px;
	color: var(--bc);
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	letter-spacing: .5px;
	margin-bottom: 1px;
	font-weight: 400;
}

.metaportal_fn_mintbox .mint_list h3 {
	margin: 0;
	padding: 0;
	font-size: 24px;
	letter-spacing: 0;
	font-weight: 400;
	color: #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.metaportal_fn_mintbox .mint_desc p {
	max-width: 400px;
	font-size: 16px;
	margin: 0;
	line-height: 1.5;
	color: var(--bc);
	margin-top: 25px;
}

.metaportal_fn_mintbox .mint_desc {
	display: flex;
	/* justify-content: center; */
	flex-direction: column;
	/* align-items: center; */
}

.metaportal_fn_mintbox .mint_desc p a {
	text-decoration: none;
	color: #ccc;
}

.metaportal_fn_mintbox .mint_desc p a:hover {
	color: var(--mc1);
}

.metaportal_fn_mintbox .mint_time {
	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, .15);
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 45px;
}

.metaportal_fn_mintbox .mint_time:after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	bottom: 0;
	height: 1px;
	background-color: var(--mc1);
	transition: all .3s ease;
}

.metaportal_fn_mintbox .mint_time:hover:after {
	width: 100%;
}

.metaportal_fn_mintbox .mint_time h4 {
	font-size: 16px;
	color: #b579da;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	letter-spacing: .5px;
	margin-bottom: 1px;
	font-weight: 600;
}

.metaportal_fn_mintbox .mint_time h3 {
	margin: 0;
	padding: 0;
	font-size: 24px;
	letter-spacing: 0;
	font-weight: 400;
	color: #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.metaportal_fn_mintbox .mright {
	/* max-width: 275px; */
	margin: 0 auto;

}

.metaportal_fn_mintbox .mint_checked {
	margin-bottom: 37px;
}

.metaportal_fn_mintbox .mint_checked p {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
	margin-bottom: 7px;
}

.metaportal_fn_mintbox .mint_checked p:last-child {
	margin-bottom: 0;
}

.metaportal_fn_mintbox .mint_checked .text {
	margin-right: 5px;
	font-family: var(--hff);
	font-size: 18px;
	letter-spacing: 0;
	color: var(--bc);
}

.metaportal_fn_mintbox .mint_checked .status {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	font-family: var(--hff);
	color: #ddd;
	letter-spacing: .5px;
	text-transform: uppercase;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_mintbox .mint_checked .icon {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	background-color: #56b201;
	color: #000;
	border-radius: 100%;
	margin-left: 5px;
	position: relative;
	top: -2px;
}

.metaportal_fn_mintbox .mint_checked .fn__svg {
	width: 8px;
	height: 8px;
	display: block;
}

.metaportal_fn_mintbox .mint_info p {
	margin: 15px 0;
	font-size: 15px;
	line-height: 1.5;
	color: var(--bc);
}

.mint_info h4{
	margin-top: 20px;
	margin-bottom: 10px;
}

.metaportal_fn_nft_cats {
	margin-bottom: 102px;
}

.metaportal_fn_nft_cats ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-left: -40px;
}

.metaportal_fn_nft_cats li {
	width: 40%;
	padding-left: 40px;
	margin-bottom: 45px;
}

.metaportal_fn_nft_cats .item {
	height: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	position: relative;
	padding-bottom: 10px;
}

.metaportal_fn_nft_cats .item:after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	bottom: 0;
	height: 1px;
	background-color: var(--mc1);
	transition: all .3s ease;
}

.metaportal_fn_nft_cats .item:hover:after {
	width: 100%;
}

.metaportal_fn_nft_cats .parent_category {
	font-size: 16px;
	color: var(--bc);
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	letter-spacing: .5px;
	margin-bottom: 1px;
	font-weight: 400;
}

.metaportal_fn_nft_cats .child_category {
	margin: 0;
	padding: 0;
	font-size: 24px;
	letter-spacing: 0;
	font-weight: 400;
	color: #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}




/* Drops */
.metaportal_fn_drops .grid {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -40px;
}

.metaportal_fn_drops .grid>li {
	width: 33.3333%;
	padding-left: 40px;
	margin-bottom: 40px;
}

.nft__item {
	width: 100%;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, .5);
	/* background-color: rgba(255, 255, 255, .05); */
	background-color: rgba(37, 37, 37, 0.15);
	position: relative;
	text-align: center;
	backdrop-filter: blur(5px);
}

.nft__item:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 10px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.nft__item:hover:after {
	opacity: .5;
}

.nft__item .fn_title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	color: #ccc;
	line-height: 21px;
}

.nft__item .img_holder {
	border-radius: 10px;
	overflow: hidden;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin-bottom: 22px; */
}

.nft__item .img_holder img {
	width: 100%;
	/* Make the image take 100% of the container's width */
	/* height: 100%; Allow the height to adjust proportionally based on the image's aspect ratio */
	object-fit: cover !important;
	/* margin-bottom: 22px; */
}

.metaportal_fn_similar {
	margin-bottom: 110px;
}

.metaportal_fn_similar .fn__maintitle {
	margin-bottom: 15px;
}

.metaportal_fn_similar .fn_cs_divider {
	margin-bottom: 29px;
}



.metaportal_fn_collections .metaportal_fn_drops {
	padding: 100px 0 20px;
}









/* Blog Single */
/* Mini Items */
.metaportal_fn_minis {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 100%;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	-ms-align-items: center;
	align-items: center;
	flex-wrap: wrap;
}

.metaportal_fn_minis .m_item {
	line-height: 30px;
}

.metaportal_fn_minis .m_item>* {
	padding: 0 15px;
	line-height: 38px;
	display: block;
	text-decoration: none;
	background-color: #000;
	color: var(--bc);
	font-size: 16px;
	font-family: var(--hff);
	letter-spacing: 0;
	position: relative;
	margin-right: 10px;
	border-radius: 30px;
}

.metaportal_fn_minis .m_item>*:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 30px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_minis .m_item>*:hover:after {
	opacity: .5;
}

.metaportal_fn_minis .m_item a {
	text-decoration: none;
	color: var(--bc);
}

.metaportal_fn_minis .m_item a:hover {
	color: #ccc;
}


.metaportal_fn_blog_single {
	padding-top: 165px;
}

.metaportal_fn_blog_single .single_img {
	margin-bottom: 50px;
	border-radius: 15px;
	overflow: hidden;
}

.metaportal_fn_blog_single .fn__maintitle {
	margin-bottom: 13px;
}



.metaportal_fn_wsidebar {
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
}

.metaportal_fn_wsidebar .sidebar_left {
	width: 100%;
	padding-right: 50px;
	padding-bottom: 110px;
}

.metaportal_fn_wsidebar .sidebar_right {
	width: 350px;
	min-width: 350px;
	padding-left: 50px;
	padding-bottom: 110px;
}

.metaportal_fn_blog_single .metaportal_fn_minis {
	margin-bottom: 19px;
}

.metaportal_fn_blog_single .single_title .fn_title {
	margin: 0;
	padding: 0;
	font-size: 36px;
	font-weight: 500;
	line-height: 1.3333;
	margin-bottom: 13px;
}

.metaportal_fn_blog_single .categories {
	color: var(--bc);
	font-family: var(--hff);
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 38px;
}

.metaportal_fn_blog_single .categories a {
	color: inherit;
	text-decoration: none;
}

.metaportal_fn_blog_single .categories a:hover {
	color: var(--hc);
}

.metaportal_fn_blog_single .single_desc p {
	margin-bottom: 28px;
}

@media(width<900px) {
	.single_desc {
		padding: 0 0.5rem;
	}
}

.metaportal_fn_blog_single .single_desc p:last-child {
	margin-bottom: 0;
}

/*------------------------------------------------------------------*/
/*	xx) Author Information Box (used for single page of the blog)
/*------------------------------------------------------------------*/
.metaportal_fn_author_info {
	background-color: rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(255, 255, 255, .07);
	border-radius: 5px;
	padding: 40px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	-ms-align-items: center;
	align-items: center;
	margin: 52px 0 27px 0;
}

.metaportal_fn_author_info .info_img {
	border-radius: 10px;
	overflow: hidden;
	margin-right: 40px;
	width: 25%;
	min-width: 25%;
}

.metaportal_fn_author_info .info_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.metaportal_fn_author_info .fn_title {
	margin: 0;
	padding: 0;
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 17px;
}

.metaportal_fn_author_info .fn_desc {
	margin: 0;
	padding: 0;
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 18px;
}

.metaportal_fn_author_info .author_social {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_author_info .author_social li {
	margin: 5px 28px 5px 0;
	padding: 0;
}

.metaportal_fn_author_info .author_social a {
	color: var(--bc);
	font-size: 20px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	text-align: center;
	line-height: 30px;
}

.metaportal_fn_author_info .author_social a:hover {
	color: var(--hc);
}



.metaportal_fn_tags {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 100%;
	-ms-align-items: center;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	margin-right: 20px;
	color: var(--bc);
	font-family: var(--hff);
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
}

.metaportal_fn_tags .label {
	margin: 0;
	padding: 0;
	margin-right: 10px;
	color: #ccc;
	font-family: var(--hff);
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
}

.metaportal_fn_tags a {
	text-decoration: none;
	color: inherit;
}

.metaportal_fn_tags span {
	margin-right: 5px;
}

.metaportal_fn_tags a:hover {
	color: var(--hc);
}


.widget {
	margin-bottom: 60px;
}

.widget:last-child {
	margin-bottom: 0;
}

.wid-title {
	position: relative;
	margin-bottom: 30px;
}

.wid-title .text {
	display: block;
	padding: 8px 10px;
	line-height: 24px;
	letter-spacing: .5px;
	position: relative;
	text-transform: uppercase;
	color: #ddd;
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-weight: 500;
	background-color: rgba(37, 37, 37, 0.05);
	text-align: center;
	font-family: var(--hff);
	border: 2px solid rgba(255, 255, 255, .5);
	border-radius: 30px;
}

.wid-title .text:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 30px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.wid-title .text:hover:after {
	opacity: 1;
}

.wid-title .icon {
	width: 12px;
	height: 12px;
	display: block;
	position: absolute;
	left: 100%;
	margin-left: 20px;
	top: 50%;
	margin-top: -6px;
	border-radius: 100%;
	border: 2px solid rgba(255, 255, 255, .5);
	background-color: rgba(255, 255, 255, .05);
}

.wid-title .icon:after {
	position: absolute;
	content: '';
	display: block;
	width: 3000px;
	height: 1px;
	top: 5px;
	left: 100%;
	margin-left: 20px;
	background-color: rgba(255, 255, 255, .15);
}

.abs_img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 1s ease;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

/* Top Articles Widget */
.metaportal_fn_widget_articles ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.metaportal_fn_widget_articles li {
	margin-bottom: 20px;
}

.metaportal_fn_widget_articles li:last-child {
	margin-bottom: 0;
}

.metaportal_fn_widget_articles .item {
	position: relative;
	width: 100%;
	padding: 26px 20px 24px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, .15);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
	transition: all .3s ease;
}

.metaportal_fn_widget_articles .fn_title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	line-height: 1.3333;
	margin-bottom: 9px;
}

.metaportal_fn_widget_articles .fn_date {
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-family: var(--hff);
	letter-spacing: 0;
	color: var(--bc);
	overflow: hidden;
	position: relative;
}

.metaportal_fn_widget_articles .post_date:after {
	width: 4px;
	height: 4px;
	content: '';
	background-color: #777;
	border-radius: 100%;
	margin-left: 5px;
	display: inline-block;
	vertical-align: middle;
}

.metaportal_fn_widget_articles .count {
	display: block;
	width: 40px;
	height: 40px;
	font-weight: 500;
	font-family: var(--hff);
	font-size: 18px;
	letter-spacing: 0;
	color: #ccc;
	background-color: #111;
	border-radius: 100%;
	text-align: center;
	line-height: 40px;
	bottom: -10px;
	right: 0;
	position: absolute;
	transition: all .3s ease;
}

.metaportal_fn_widget_articles .count:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_widget_articles .item:hover .fn_date .post_date {
	transform: translateY(-100%);
}

.metaportal_fn_widget_articles .item:hover .fn_date .comment_count {
	transform: translateY(-100%);
}


/* Custom Categories */
.metaportal_fn_categories ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}

.metaportal_fn_categories li {
	margin: 0px 0px 10px 0px;
	width: 100%;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

.metaportal_fn_categories li a {
	position: relative;
	width: 100%;
	max-width: calc(100% - 55px);
	padding-right: 20px;
	z-index: 5;
	font-size: 16px;
	font-weight: 500;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	line-height: 30px;
	font-style: normal;
	color: #ddd;
	text-decoration: none;
	font-family: var(--hff);
	text-transform: uppercase;
	overflow: hidden;
}

.metaportal_fn_categories li a span {
	position: relative;
}

.metaportal_fn_categories li a span:after {
	content: '';
	position: absolute;
	background-color: rgba(255, 255, 255, .1);
	height: 1px;
	left: 100%;
	margin-left: 20px;
	width: 2000px;
	top: 50%;
	margin-top: -1px;
	margin-top: -0.5px;
}

.metaportal_fn_categories li a span:before {
	content: '';
	position: absolute;
	background-color: rgba(255, 255, 255, .1);
	height: 1px;
	right: 100%;
	margin-right: 20px;
	width: 2000px;
	top: 50%;
	margin-top: -1px;
	margin-top: -0.5px;
}

.metaportal_fn_categories li a:after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 2000px;
	z-index: 5;
}

.metaportal_fn_categories li a:before {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	right: 100%;
	width: 40px;
	z-index: 5;
}

.metaportal_fn_categories .count {
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	font-size: 11px;
	font-family: var(--hff);
	letter-spacing: 0;
	color: #ddd;
	background-color: #974EC382;
	border-radius: 100%;
	line-height: 30px;
	position: relative;
	z-index: 3;
}

.metaportal_fn_categories .count:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	color: var(--mc1);
	top: 0;
	left: 0;
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_categories li:hover a {
	padding-left: 40px;
	color: #b572dfd0;
}

.metaportal_fn_categories li a.activa {
	padding-left: 40px;
	color: #b572dfd0;
}

.metaportal_fn_categories .more {
	font-size: 13px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	font-family: var(--hff);
	color: #777;
	text-transform: uppercase;
	text-decoration: none;
	display: block;
	text-align: center;
	margin-top: 1px;
}

.metaportal_fn_categories .more:hover {
	color: #ccc;
}



.metaportal_fn_widget_social ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	margin-left: -20px;
}

.metaportal_fn_widget_social ul li {
	margin: 5px 0px 5px 20px;
	padding: 0;
}

.metaportal_fn_widget_social ul a {
	color: var(--bc);
	font-size: 18px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	text-align: center;
	line-height: 30px;
}

.metaportal_fn_widget_social ul a:hover {
	color: var(--hc);
}




/* Previous & Next Box */
.metaportal_fn_pnb {
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	border-top: 1px solid rgba(255, 255, 255, .07);
	border-bottom: 1px solid rgba(255, 255, 255, .07);
}

.metaportal_fn_pnb .pnb_wrapper {
	width: 100%;
	max-width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	position: relative;
	overflow: hidden;
}

.metaportal_fn_pnb .item {
	width: 46.55%;
	max-width: calc(50% - 43px);
	max-width: calc(50% - 42.5px);
	padding: 150px 0;
	position: relative;
}

.metaportal_fn_pnb .next {
	text-align: right;
}

.metaportal_fn_pnb .item_in {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_pnb .img {
	width: 80px;
	min-width: 80px;
	height: 80px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 5px;
	overflow: hidden;
	transform: translateZ(0);
}

.metaportal_fn_pnb .prev {
	padding-right: 90px;
}

.metaportal_fn_pnb .next {
	padding-left: 90px;
}

.metaportal_fn_pnb .prev .img {
	margin-right: 30px;
}

.metaportal_fn_pnb .next .item_in {
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.metaportal_fn_pnb .next .img {
	margin-left: 30px;
}

.metaportal_fn_pnb .item .desc {
	width: 100%;
	max-width: calc(100% - 110px);
}

.metaportal_fn_pnb .item .fn_desc {
	color: var(--bc);
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: var(--hff);
	font-weight: 500;
	letter-spacing: .5px;
	text-transform: uppercase;
	margin-bottom: 6px;
}

.metaportal_fn_pnb .item .fn_title {
	margin: 0;
	padding: 0;
	font-size: 22px;
	font-weight: 500;
	letter-spacing: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #ddd;
}

.metaportal_fn_pnb .fn_trigger {
	width: 80px;
	height: 80px;
	position: absolute;
	top: 50%;
	margin-top: -40px;
	left: 50%;
	margin-left: -40px;
	background-color: rgba(15, 11, 29, 0.05);
	border: 2px solid rgba(255, 255, 255, .5);
	border-radius: 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_pnb .fn_trigger:after {
	content: '';
	position: absolute;
	z-index: -1;
	left: -2px;
	right: -2px;
	bottom: -2px;
	top: -2px;
	color: var(--mc1);
	opacity: .15;
	border-radius: 5px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.metaportal_fn_pnb .fn_trigger:hover:after {
	opacity: .5;
}

.metaportal_fn_pnb .fn_trigger .icon {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 3px;
	width: 21px;
	height: 21px;
}

.metaportal_fn_pnb .fn_trigger span {
	width: 3px;
	height: 3px;
	display: block;
	background-color: #ccc;
}

.metaportal_fn_pnb .container,
.metaportal_fn_pnb .item,
.metaportal_fn_pnb .img,
.metaportal_fn_pnb .fn_trigger span,
.metaportal_fn_pnb .fn_desc,
.metaportal_fn_pnb .fn_title {
	transition: all .5s ease;
}

.metaportal_fn_pnb .item:hover .fn_desc {
	color: #aaa;
}

.metaportal_fn_pnb .item:hover .fn_title {
	color: var(--mc1);
}

.metaportal_fn_pnb .fn_trigger:hover span {
	background-color: #fff;
}





.metaportal_fn_comments {
	padding: 150px 0;
}

.metaportal_fn_comments .comment-title {
	margin-bottom: 55px;
}

.metaportal_fn_comments .comment-title .fn_title {
	font-size: 22px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--bc);
}

.metaportal_fn_comments .list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.metaportal_fn_comments .comment {
	margin-bottom: 30px;
}

.metaportal_fn_comments .comment:last-child {
	margin-bottom: 0;
}

.metaportal_fn_comments .comment-body {
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.metaportal_fn_comments .comment-avatar {
	width: 80px;
	min-width: 80px;
	margin-right: 30px;
}

.metaportal_fn_comments .comment-avatar img {
	border-radius: 100%;
}

.metaportal_fn_comments .comment-data {
	margin-bottom: 19px;
	margin-top: 5px;
}

.metaportal_fn_comments .comment-data .author {
	font-size: 20px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	margin-bottom: 8px;
}

.metaportal_fn_comments .comment-data .date {
	font-size: 13px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	font-family: var(--hff);
	color: #707381;
	text-transform: uppercase;
}

.metaportal_fn_comments .comment-text p {
	margin: 0;
	padding: 0;
	margin-bottom: 36px;
}

.metaportal_fn_comments .comment-text p:last-child {
	margin-bottom: 0;
}

.metaportal_fn_comments .comment-text .desc {
	margin-bottom: 22px;
}

.metaportal_fn_comments .fn_reply {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
}

.metaportal_fn_comments .fn_reply .fn__svg {
	width: 14px;
	height: 14px;
	display: block;
	margin-right: 5px;
}

.metaportal_fn_comments .fn_reply a {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	border: 1px solid rgba(255, 255, 255, .15);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
	line-height: 26px;
	padding: 0 10px;
	border-radius: 3px;
	text-decoration: none;
	margin: 0 5px 5px 0;
	font-size: 14px;
	font-family: var(--hff);
	color: var(--bc);
}

.metaportal_fn_comments .fn_reply a:hover {
	border-color: rgba(255, 255, 255, 0.5);
	color: #ddd;
}

.metaportal_fn_comments .comment-text-wrap {
	padding-bottom: 35px;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.metaportal_fn_comments .comment-respond {
	padding-top: 96px;
}

.metaportal_fn_comments .respond-title {
	margin-bottom: 38px;
}

.metaportal_fn_comments .respond-title .fn_title {
	font-size: 22px;
	margin: 0;
	padding: 0;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--hc);
}

.metaportal_fn_comments .respond-log {
	margin-bottom: 17px;
}

.metaportal_fn_comments .respond-log p {
	margin: 0;
	padding: 0;
	color: #ccc;
}

.metaportal_fn_comments .respond-log p a {
	text-decoration: none;
	color: inherit;
	border-bottom: 1px solid transparent;
}

.metaportal_fn_comments .respond-log p a:hover {
	border-bottom-color: inherit;
}

.metaportal_fn_comments .input-items {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -40px;
}

.metaportal_fn_comments .input-item {
	width: 100%;
	padding-left: 40px;
	margin-bottom: 40px;
}

.metaportal_fn_comments .input-item.half-item {
	width: 50%;
}

.metaportal_fn_comments .comment-form textarea,
.metaportal_fn_comments .comment-form input[type="text"] {
	width: 100%;
	min-width: 100%;
	display: block;
	color: white;
}

.metaportal_fn_comments .comment-form input[type="text"] {
	height: 80px;
	padding: 0 27px;
}

.metaportal_fn_comments .metaportal_fn_button {
	max-width: 300px;
}






/* Coming Soon Page */
.metaportal_fn_coming_soon {
	padding: 260px 0;
}

.metaportal_fn_coming_soon .soon_title {
	max-width: 600px;
	margin: 0 auto;
	text-align: center;
}

.metaportal_fn_coming_soon .soon_title h3 {
	margin: 0;
	margin-bottom: 20px;
}

.metaportal_fn_coming_soon .soon_title p {
	margin: 0;
}

.metaportal_fn_boxed_countdown {
	margin-bottom: 17px;
}

.metaportal_fn_boxed_countdown ul {
	margin: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_boxed_countdown li {
	margin: 0 0 20px 0px;
	padding-left: 20px;
}

.metaportal_fn_boxed_countdown .item {
	width: 140px;
}

.metaportal_fn_boxed_countdown .count {
	width: 100%;
	height: 120px;
	border: 1px solid rgba(255, 255, 255, .15);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.metaportal_fn_boxed_countdown .count h3 {
	font-size: 60px;
}

.metaportal_fn_boxed_countdown span {
	display: block;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	color: #ddd;
	letter-spacing: 1px;
	text-transform: uppercase;
	position: relative;
	font-weight: 700;
}

.metaportal_fn_boxed_countdown li:last-child span:after,
.metaportal_fn_boxed_countdown li:last-child span:before {
	display: none;
}

.metaportal_fn_boxed_countdown span:after {
	content: '';
	position: absolute;
	left: 100%;
	top: 50%;
	width: 12px;
	height: 12px;
	display: block;
	margin-top: -6px;
	margin-left: 4px;
	border: 2px solid rgba(255, 255, 255, .5);
	background-color: rgba(255, 255, 255, .05);
	border-radius: 100%;
}

.metaportal_fn_boxed_countdown span:before {
	content: '';
	position: absolute;
	left: 100%;
	top: 50%;
	width: 12px;
	height: 12px;
	display: block;
	margin-top: -6px;
	margin-left: 4px;
	color: var(--mc1);
	opacity: .15;
	border-radius: 100%;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
	z-index: -1;
}



.stop1 {
	stop-color: var(--mc1)
}

.stop2 {
	stop-color: var(--mc2)
}

/* Protected Page */
.metaportal_fn_protected {
	padding: 250px 0;
}

.metaportal_fn_protected .message_holder {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	text-align: center;
}

.metaportal_fn_protected h3 {
	margin-bottom: 20px;
}

.metaportal_fn_protected .icon {
	display: inline-block;
	margin-bottom: 30px;
}

.metaportal_fn_protected .icon .fn__svg {
	width: 146px;
	height: 146px;
	display: block;
}

.metaportal_fn_protected .container-custom {
	max-width: 565px;
	margin: 0 auto;
}

.metaportal_fn_protected p {
	margin-bottom: 22px;
}

.metaportal_fn_protected form {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.metaportal_fn_protected input[type="password"] {
	width: 100%;
	margin-right: 10px;
}





/* Collection Page */
.metaportal_fn_collectionpage {
	padding: 150px 0;
}

.metaportal_fn_result_list {
	overflow: hidden;
}

.metaportal_fn_collection {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.metaportal_fn_clist {
	width: 100%;
	padding-left: 50px;
	overflow: hidden;
}

.metaportal_fn_clist .metaportal_fn_title {
	margin-bottom: 50px;
}


/* Filters */
.metaportal_fn_filters {
	position: sticky;
	top: 100px;
	margin-bottom: 40px;
	/*added because items has also margin bottom 40px*/
}

.metaportal_fn_filters {
	width: 350px;
	min-width: 350px;
}

.metaportal_fn_filters .filter_item {
	margin-bottom: 10px;
	border-radius: 5px;
	overflow: hidden;
}

.metaportal_fn_filters .filter_item:last-child {
	margin-bottom: 0;
}

.metaportal_fn_filters .filter_item__header {
	position: relative;
	padding: 20px 30px;
	background-color: rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(255, 255, 255, .07);
	border-radius: 5px;
}

.metaportal_fn_filters .filter_item__header .text {
	line-height: 30px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: .5px;
	text-transform: uppercase;
	color: #ccc;
	font-family: var(--hff);
}

.metaportal_fn_filters .opened .filter_item__header .fn__svg {
	transform: rotate(180deg);
}

.metaportal_fn_filters .filter_item__header .fn__svg {
	width: 14px;
	height: 14px;
	position: absolute;
	color: var(--extra-color);
	display: block;
	top: 50%;
	margin-top: -7px;
	right: 20px;
	transition: transform 100ms ease 0s;
}

.metaportal_fn_filters .filter_item__content {
	display: none;
	width: 100%;
	height: initial;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 0 0 5px 5px;
	border: 1px solid rgba(255, 255, 255, .07);
}

.metaportal_fn_filters .ic_in {
	padding: 23px 20px 21px 30px;
}

.metaportal_fn_filters .items {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -10px;
	max-height: 200px;
	overflow-y: scroll;
}

.metaportal_fn_filters .items {
	scrollbar-width: thin;
	scrollbar-color: #999 #fff;
}

.metaportal_fn_filters .items::-webkit-scrollbar {
	width: 2px;
}

.metaportal_fn_filters .items:-webkit-scrollbar-track {
	background: var(--extra-color);
}

.metaportal_fn_filters .items::-webkit-scrollbar-thumb {
	background-color: var(--extra-color);
}

.metaportal_fn_filters .items>li {
	margin: 0;
	padding: 0;
	padding-left: 10px;
	margin-bottom: 3 px;
	width: 100%;
}

.metaportal_fn_filters .items>li:last-child {
	margin-bottom: 0;
}

.metaportal_fn_filters .checkbox {
	width: 100%;
	display: block;
	position: relative;
	padding: 5px 0 5px 26px;
	cursor: pointer;
	font-size: 16px;
	color: #ddd;
	font-family: var(--hff);
}

.metaportal_fn_filters .checkbox .amount {
	color: var(--bc);
}

.metaportal_fn_filters .checkbox .checkmark .fn__svg {
	width: 12px;
	height: 12px;
	position: relative;
	top: 0px;
	left: 1px;
	display: none;
	color: #ddd;
}

.metaportal_fn_filters .checkbox input:checked~.checkmark {
	border-color: rgba(255, 255, 255, .3);
}

.metaportal_fn_filters .checkbox input:checked~.checkmark .fn__svg {
	display: block;
}

.metaportal_fn_filters .checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.metaportal_fn_filters .checkbox .checkmark {
	width: 16px;
	height: 16px;
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -9px;
	left: 0;
	border: 2px solid rgba(255, 255, 255, .15);
	border-radius: 3px;
}

.metaportal_fn_filters .checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}


/* Result Box */
.metaportal_fn_result_box {
	width: 100%;
	border: 1px solid rgba(255, 255, 255, .07);
	background-color: rgba(0, 0, 0, 0.3);
	min-height: 70px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-align-items: center;
	align-items: center;
	padding: 15px 20px 5px;
	border-radius: 5px;
	margin-bottom: 50px;
}

.metaportal_fn_result_box>* {
	margin: 0 10px 10px 0;
}

.metaportal_fn_result_box .filter_count {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	font-size: 14px;
	font-family: var(--hff);
	font-weight: 300;
	height: 40px;
	line-height: 36px;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, .15);
	border-radius: 5px;
	padding: 0 0 0 20px;
	position: relative;
}

.metaportal_fn_result_box .filter_count span {
	position: relative;
	min-width: 34px;
	padding: 0 8px;
	display: block;
	right: 0;
	text-align: center;
	white-space: nowrap;
	margin-left: 20px;
	color: #ddd;
}

.metaportal_fn_result_box .filter_count span:after {
	right: 100%;
	width: 1px;
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, .15);
}

.metaportal_fn_result_box .result_item a {
	position: relative;
	border-radius: 5px;
	display: block;
	text-decoration: none;
	padding: 0 56px 0 20px;
	line-height: 36px;
	white-space: nowrap;
	font-size: 14px;
	color: var(--bc);
	font-family: var(--hff);
	border: 1px solid rgba(255, 255, 255, .15);
}

.metaportal_fn_result_box .result_item span {
	color: #ddd;
}

.metaportal_fn_result_box .result_item a:after {
	width: 1px;
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 34px;
	background-color: rgba(255, 255, 255, .15);
	transition: all .3s ease;
}

.metaportal_fn_result_box .result_item .fn__svg {
	width: 10px;
	height: 10px;
	display: block;
	position: absolute;
	right: 12px;
	top: 13px;
	color: var(--extra-color);
}

.metaportal_fn_result_box .result_item a:before {
	width: 1px;
	content: '';
	position: absolute;
	top: 0;
	height: 0;
	right: 34px;
	background-color: rgba(255, 255, 255, .3);
	transition: all .3s ease;
}

.metaportal_fn_result_box .result_item a:hover {
	border-color: rgba(255, 255, 255, .3);
}

.metaportal_fn_result_box .result_item a:hover .fn__svg {
	color: #ddd;
}

.metaportal_fn_result_box .result_item a:hover:after {
	opacity: 0;
}

.metaportal_fn_result_box .result_item a:hover:before {
	height: 100%;
}

.metaportal_fn_result_box .clear_all {
	font-size: 14px;
	color: #666;
	letter-spacing: 0;
	font-family: var(--hff);
	text-decoration: none;
	line-height: 40px;
	padding: 0 10px;
	margin-right: 0;
}

.metaportal_fn_result_box .clear_all:hover {
	color: var(--mc1);
}




.metaportal_fn_404 {
	padding: 250px 0;
}

.metaportal_fn_404 .icon {
	display: inline-block;
	margin-bottom: 37px;
}

.metaportal_fn_404 .icon .fn__svg {
	width: 146px;
	height: 146px;
	display: block;
}

.metaportal_fn_404 .message_holder {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	text-align: center;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
}

.metaportal_fn_404 h3 {
	font-size: 200px;
	font-weight: 900;
	color: #1b0e37;
	letter-spacing: 2px;
	margin-bottom: 2px;
}

.metaportal_fn_404 h4 {
	margin-bottom: 20px;
}

.metaportal_fn_404 p {
	max-width: 420px;
	margin: 0 auto;
	margin-bottom: 22px;
}

.metaportal_fn_404 .container-custom {
	max-width: 565px;
	width: 100%;
	margin: 0 auto;
}

.metaportal_fn_404 form {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.metaportal_fn_404 input {
	width: 100%;
	margin-right: 10px;
}

.metaportal_fn_404 a {
	min-width: 153px;
}

.metaportal_fn_404 a .fn__svg {
	position: absolute;
	width: 18px;
	height: 18px;
	display: block;
	left: 50%;
	top: 50%;
	margin: -9px 0 0 -9px;
}


/* Privacy Policy Page */
.metaportal_fn_privacy {
	padding: 138px 0 150px;
}

.metaportal_fn_privacy h3 {
	margin-bottom: 15px;
	padding-top: 12px;
}

.metaportal_fn_privacy h3:before {
	top: 14px;
}

.metaportal_fn_privacy .fn_cs_divider {
	margin-bottom: 25px;
}

.metaportal_fn_privacy p {
	margin-bottom: 27px;
}

.metaportal_fn_privacy *:last-child {
	margin-bottom: 0;
}


@media(max-width: 1500px) {
	.metaportal_fn_result_list .metaportal_fn_drops .grid>li {
		width: 50%;
	}
}

@media(max-width: 1400px) {
	.metaportal_fn_wsidebar .sidebar_left {
		padding-right: 25px;
	}

	.metaportal_fn_wsidebar .sidebar_right {
		padding-left: 25px;
	}

	.fn__gradient_title,
	.fn__maintitle.big {
		font-size: 40px;
	}

	.fn_cs_slider .slider_top li.prev {
		left: -30px;
	}

	.fn_cs_slider .slider_top li.next {
		margin-left: 30px;
	}

	.fn_cs_counter_list li {
		padding-left: 80px;
	}

	.fn_cs_counter_list ul {
		margin-left: -80px;
	}

	.fn_cs_counter_list .divider {
		width: 80px;
	}

	.fn_cs_about .right_part {
		padding: 110px 50px;
		width: 50%;
	}

	.fn_cs_about .left_part {
		width: 50%;
	}

	.fn_cs_about .left_part .bg_overlay {
		right: 80px;
	}

	.fn_cs_steps ul {
		margin-left: -40px;
	}

	.fn_cs_steps li {
		padding-left: 40px;
		margin-bottom: 40px;
	}

	.fn_cs_steps {
		margin-bottom: 30px;
	}

	.fn_cs_mint .left_part {
		padding-right: 50px;
	}

	.fn_cs_collection .collection_top {
		margin-left: -20px;
		padding-right: 20px;
	}

	.fn_cs_collection .collection_bottom {
		margin-left: -20px;
		padding-left: 20px;
	}

	.fn_cs_collection .item {
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.fn_cs_contact_info li {
		padding-left: 50px;
	}

	.fn_cs_contact_info ul {
		margin-left: -50px;
	}

	.fn_cs_contact_form li {
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.fn_cs_contact_form ul {
		margin-left: -20px;
	}
}

@media(max-width: 1200px) {
	#social {
		display: none;
	}

	.metaportal_fn_totop .totop_inner {
		/* transform: rotate(-90deg); */
		top: 0;
		left: 0;
	}

	.metaportal_fn_totop .icon {
		margin-right: 0;
	}

	.metaportal_fn_totop .text {
		display: none;
	}

	.fn_cs_mint {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.fn_cs_mint .left_part {
		padding-right: 0;
		width: 100%;
		margin-bottom: 80px;
	}

	.fn_cs_mint .right_part {
		width: 100%;
	}

	.fn_cs_contact_info li {
		width: 100%;
	}
}

@media(max-width: 1040px) {
	.metaportal_fn_blog_single {
		padding-top: 145px;
	}

	.metaportal_fn_wsidebar {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_wsidebar .sidebar_left {
		padding-right: 0;
	}

	.metaportal_fn_wsidebar .sidebar_right {
		padding-left: 0;
		width: 100%;
		min-width: 100%;
	}

	.metaportal_fn_pnb .item {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.metaportal_fn_protected,
	.metaportal_fn_404,
	.metaportal_fn_coming_soon {
		padding: 130px 0;
	}

	.metaportal_fn_404 h3 {
		font-size: 12vw;
	}

	.metaportal_fn_collection {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_filters {
		width: 100%;
		min-width: 100%;
	}

	.metaportal_fn_clist {
		padding-left: 0;
	}

	.metaportal_fn_search.hold {
		transform: translateX(300px);
	}

	.metaportal_fn_mint_top {
		padding-top: 150px;
	}

	#home {
		padding-top: 150px;
	}

	.metaportal_fn_searchbox input {
		font-size: 26px;
		height: 70px;
	}

	.metaportal_fn_searchbox a {
		width: 70px;
		height: 70px;
	}

	.metaportal_fn_searchbox a .fn__svg {
		width: 26px;
		height: 26px;
	}

	.metaportal_fn_mobnav {
		display: block;
	}

	.metaportal_fn_search {
		right: 30px;
		left: auto;
		bottom: 30px;
	}

	.totop-active .metaportal_fn_search {
		bottom: 90px;
	}

	#header {
		display: none;
	}

	.fn_cs_counter_list li {
		width: 50%;
	}

	.fn_cs_counter_list li:nth-of-type(2n) .divider {
		display: none;
	}

	.fn_cs_about {
		-webkit-flex-direction: column-reverse;
		-moz-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		-o-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.fn_cs_about .left_part {
		width: 100%;
		justify-content: center;
	}

	.fn_cs_about .left_part .bg_overlay {
		right: 0;
	}

	.fn_cs_about .right_part {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0;
	}

	.fn_cs_about .right_in {
		max-width: 100%;
	}

	.fn_cs_news .news_part {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.fn_cs_news .left_items {
		width: 100%;
		margin-bottom: 20px;
	}

	.fn_cs_news .right_items {
		width: 100%;
		gap: 20px;
	}

	.fn_cs_news .bottom_part {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.fn_cs_news .bottom_part .left_bot,
	.fn_cs_news .bottom_part .right_bot {
		width: 100%;
	}

	.fn_cs_news .bottom_part .right_bot {
		margin-top: 20px;
	}

	.fn_cs_news .bottom_part p {
		padding: 0;
	}

	.fn_cs_news .item {
		padding: 30px;
	}
}

@media(max-width: 900px) {
	.metaportal_fn_mintbox .mint_title span {
		padding-left: 49px;
	}

	.metaportal_fn_mint_top {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_mint_top .mint_left {
		width: 100%;
		margin-bottom: 50px;
	}

	.metaportal_fn_mint_top .mint_right {
		width: 100%;
		padding-left: 0;
	}

	.metaportal_fn_mintbox {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_mintbox .mint_left {
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}

	.metaportal_fn_mintbox .mint_right {
		width: 100%;
		padding: 50px 40px;
	}

	.metaportal_fn_mintbox .mright {
		max-width: 100%;
	}

	.metaportal_fn_nft_cats li {
		width: 50%;
	}

	.metaportal_fn_drops .grid>li {
		width: 50%;
	}
}

@media(max-width: 768px) {
	.metaportal_fn_pnb .fn_trigger {
		position: relative;
		top: 0;
		margin-top: 0;
	}

	.metaportal_fn_pnb .pnb_wrapper {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_pnb .item {
		width: 100%;
		max-width: 100%;
	}

	.metaportal_fn_comments .input-items {
		margin-left: -20px;
	}

	.metaportal_fn_comments .input-item.half-item {
		width: 100%;
	}

	.metaportal_fn_comments .input-item {
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.metaportal_fn_result_list .metaportal_fn_drops .grid>li {
		width: 100%;
	}

	.fn_cs_collection .collection_top {
		padding-right: 0px;
	}

	.fn_cs_collection .collection_bottom {
		padding-left: 0px;
	}

	.metaportal_fn_drops .grid>li {
		width: 100%;
	}

	.metaportal_fn_bloglist .bloglist li {
		width: 100%;
	}

	.metaportal_fn_leftnav .copyright p,
	.metaportal_fn_leftnav .nav_holder ul {
		text-align: center;
	}

	.metaportal_fn_leftnav .social_icons ul {
		justify-content: center;
	}

	.fn__closer {
		display: block;
	}

	.metaportal_fn_leftnav,
	.metaportal_fn_items .item,
	.metaportal_fn_walletbox {
		width: 100%;
	}

	.metaportal_fn_leftnav .copyright p {
		max-width: 100%;
	}

	.fn_cs_slider .slider_top li.next {
		margin-left: 10px;
	}

	.fn_cs_slider .slider_top li.prev {
		left: -10px;
	}

	.fn_cs_slider .slider_top .img {
		left: 10px;
		right: 10px;
		top: 10px;
		bottom: 10px;
	}

	.footer {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		-ms-align-items: flex-start;
		align-items: flex-start;
	}

	.footer .left_part {
		margin-bottom: 10px;
	}

	.fn_cs_collection .item {
		width: 50% !important;
	}

	.fn_cs_contact_form li {
		width: 100%;
	}

	.metaportal_fn_totop {
		right: 20px;
		bottom: 20px;
	}

	.metaportal_fn_search {
		right: 20px;
		bottom: 20px;
	}

	.totop-active .metaportal_fn_search {
		bottom: 80px;
	}

	.metaportal_fn_button {
		padding: 0 24px;
	}

	.metaportal_fn_boxed_countdown li {
		width: 25%;
	}

	.metaportal_fn_boxed_countdown .item {
		width: 100%;
	}

	.metaportal_fn_boxed_countdown .count {
		height: 100px;
		height: 17vw;
	}

	.metaportal_fn_boxed_countdown .count h3 {
		font-size: 40px;
		font-size: 8vw;
	}

	.metaportal_fn_boxed_countdown span {
		font-size: 14px;
	}
}

@media(max-width: 480px) {
	.metaportal_fn_pnb .fn_trigger {
		left: 0;
		margin-left: 0;
		width: 100%;
	}

	.metaportal_fn_comments .comment-avatar {
		margin-right: 20px;
	}

	.metaportal_fn_author_info {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		padding: 30px 20px;
		-ms-align-items: flex-start;
		align-items: flex-start;
	}

	.metaportal_fn_author_info .info_img {
		width: 50%;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.metaportal_fn_protected form {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.metaportal_fn_protected input[type="password"] {
		margin: 0 0 10px 0;
	}

	.metaportal_fn_nft_cats li,
	.metaportal_fn_mintbox .mint_list li,
	.fn_cs_steps li {
		width: 100%;
	}

	.fn_cs_collection .item {
		width: 100% !important;
	}

	.fn_cs_counter_list .item {
		padding: 0 0 20px 0;
	}

	.fn_cs_counter_list .divider {
		position: absolute;
		top: 100%;
		margin-top: 5px;
		left: 50%;
		margin-left: -40px;
		display: block !important;
	}

	.fn_cs_counter_list li:last-child .divider {
		display: none !important;
	}

	.fn_cs_counter_list li {
		width: 100%;
	}

	.social a,
	.social a:after {
		font-size: 14px;
	}
}

/* Since v4.0 */
#home2 {
	padding-top: 179px;
	position: relative;
}

#home2 .container {
	position: relative;
	z-index: 5;
}

#home2 .fn__maintitle {
	margin-bottom: 39px;
}

@media(max-width: 1040px) {
	#home2 {
		padding-top: 150px;
	}
}

.metaportal_fn_preloader {
	display: none !important;
}

.frenify_cards_gallery {
	width: 100%;
	float: left;
	clear: both;
	position: relative;
	padding-top: 40px;
	padding-bottom: 100px;
	overflow: hidden;
}

.ripple {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.video_bg,
.canvas_bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.video_bg video {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#space {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #10051f;
	width: 100% !important;
	height: 100% !important;
}

.frenify_cards_gallery .img_holder {
	padding: 20px;
	border-radius: 20px;

	background-color: rgba(255, 255, 255, .05);
}

.frenify_cards_gallery .img_holder:hover:after {
	opacity: .5;
}

.frenify_cards_gallery .img_holder:before {
	border: 1px solid rgba(255, 255, 255, .5);
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 20px;
}

.frenify_cards_gallery .img_holder:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 20px;
	box-shadow: 0px 5px 20px;
}

.frenify_cards_gallery .item_in {
	position: relative;
	border-radius: 15px;
	overflow: hidden;
}

.frenify_cards_gallery ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	position: relative;
}

.frenify_cards_gallery ul li {
	margin: 0;
	padding: 0;
	position: absolute;
	display: block;
	left: 50%;
	top: 0;
	opacity: 0;
	z-index: -5;
	cursor: pointer;
	transform: scale(0);
	outline: 0;
	overflow: hidden;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all 1s ease;
	will-change: width, height, left, opacity, transform;
	transform-origin: bottom;
	box-shadow: 0 5px 40px rgba(0, 0, 0, .3);
}

.frenify_cards_gallery ul li.current {
	opacity: 1;
	cursor: default;
	z-index: 20;
}

.frenify_cards_gallery ul li.next1,
.frenify_cards_gallery ul li.prev1 {
	opacity: 1;
	z-index: 10;
}

.frenify_cards_gallery ul li.next2,
.frenify_cards_gallery ul li.prev2 {
	opacity: 1;
	z-index: 5;
}

.frenify_cards_gallery .img_holder {
	width: 100%;
	position: relative;
	z-index: 15;
}

.frenify_cards_gallery .current .img_holder a {
	display: block;
}

.frenify_cards_gallery .img_holder a {
	z-index: 20;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
}

.frenify_cards_gallery .o_img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.frenify_cards_gallery img {
	opacity: 0;
}






#about2 {
	padding: 150px 0;
}

#about2 .fn_cs_steps {
	padding-top: 150px;
}

.fn_cs_shortabout {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.fn_cs_shortabout .about_left {
	width: 49.8%;
	max-width: calc(50% - 2px);
	background-color: rgba(255, 255, 255, .05);
	border-radius: 20px 0 0 20px;
	padding: 118px 70px 120px;
}

.fn_cs_shortabout .about_right {
	width: 49.8%;
	max-width: calc(50% - 2px);
	position: relative;
}

.fn_cs_shortabout .about_right:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 0 20px 20px 0;
	border: 3px solid transparent;
	background: -moz-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: -webkit-linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	background: linear-gradient(45deg, var(--mc1), var(--mc2), var(--mc1), var(--mc2)) border-box;
	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}

.fn_cs_shortabout .about_right:before {
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	position: absolute;
	color: var(--mc1);
	content: '';
	opacity: .15;
	border-radius: 0 20px 20px 0;
	box-shadow: 0px 5px 20px;
}

.fn_cs_shortabout .about_right .abs_img {
	top: 46px;
	left: 46px;
	right: 46px;
	bottom: 46px;
	border-radius: 10px;
}

.fn_cs_shortabout .fn__maintitle {
	margin-bottom: 15px;
}

.fn_cs_shortabout .fn_cs_divider {
	margin-bottom: 25px;
}

.fn_cs_shortabout .desc {
	margin-bottom: 32px;
}

@media(max-width: 1040px) {
	.fn_cs_shortabout .about_left {
		padding: 48px 50px 50px;
	}
}

@media(max-width: 768px) {
	.fn_cs_shortabout {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.fn_cs_shortabout .about_left {
		width: 100%;
		max-width: 100%;
		border-radius: 20px;
		margin-bottom: 20px;
		padding: 38px 30px 40px;
	}

	.fn_cs_shortabout .about_right {
		width: 100%;
		max-width: 100%;
		min-height: 500px;
		min-height: 80vh;

	}

	.fn_cs_shortabout .about_right:after,
	.fn_cs_shortabout .about_right:before {
		border-radius: 20px;
	}

	.fn_cs_shortabout .about_right .abs_img {
		top: 26px;
		left: 26px;
		right: 26px;
		bottom: 26px;
	}
}




.fn_cs_collection_info {
	padding: 140px 0 142px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: center;
	align-items: center;
	max-width: 720px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	text-align: center;
}

.fn_cs_collection_info *:last-child {
	margin-bottom: 0;
}

.fn_cs_collection_info .fn__gradient_title {
	line-height: 1;
	font-size: 150px;
	font-size: 7.82vw;
	font-size: calc(75px + 3.91vw);
	margin-bottom: 33px;
	-webkit-text-stroke: 8px transparent;
	color: #250d44;
}

.fn_cs_collection_info .fn__maintitle {
	margin-bottom: 30px;
}











.fn_cs_join {
	border-radius: 20px;
	background-color: rgba(255, 255, 255, .03);
	padding: 50px 40px 50px;
}

.fn_cs_join .join_in {
	max-width: 900px;
	margin: 0 auto;
	text-align: center;
}

.fn_cs_join .fn__maintitle {
	margin-bottom: 28px;
}

.fn_cs_join p {
	/* margin-bottom: 42px; */
}

.fn_cs_join .buttons {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	flex-wrap: wrap;
	margin-left: -20px;
}

.fn_cs_join .buttons>* {
	margin: 0 0 20px 20px;
}





#faq {
	display: flex;
	justify-content: center;
	align-items: center;
}

.fn_cs_faq {
	width: 90%;
}

.fn_cs_faq .faq_col {
	width: 33.3333%;
	padding-left: 60px;
	margin-bottom: 60px;
}

.faq_col_cont {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-left: -60px;
	flex-wrap: wrap;
}

.fn_cs_faq .fn__maintitle {
	margin-bottom: 15px;
}

.desc>img {
	max-height: 50vh;
	width: 100%;
	object-fit: contain;
}

.fn_cs_faq .fn_cs_divider {
	margin-bottom: 25px;
}

@media(max-width: 1200px) {
	.fn_cs_faq .faq_col {
		width: 100%;
		padding-right: 10px;
		margin-left: 10px;
	}

	.fn_cs_faq .faq_col:nth-child(2) {
		margin-bottom: 0;
	}

	.fn_cs_faq .faq_col:nth-child(3) {
		margin-bottom: 0;
	}

	#faq {
		padding: 0px 0 0px;
	}

	.desc>img {
		max-height: 30vh;
	}
}

@keyframes shadowAnim {
	0% {
		box-shadow: 0px 5px 20px rgba(135, 223, 255, 0.8);
	}

	25% {
		box-shadow: 0px 5px 20px rgba(135, 255, 155, 0.8);
	}

	50% {
		box-shadow: 0px 5px 20px rgba(255, 255, 135, 0.8);
	}

	75% {
		box-shadow: 0px 5px 20px rgba(255, 213, 135, 0.8);
	}

	100% {
		box-shadow: 0px 5px 20px rgba(135, 223, 255, 0.8);
	}
}

.fn_cs_accordion .acc_item {
	padding: 7px 30px 5px;
	border: 1px solid rgba(255, 255, 255, .15);
	animation: shadowAnim 2s infinite;
	border-radius: 10px;
	margin-bottom: 20px;
}

.fn_cs_accordion .fn__maintitle {
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0;
}

.fn_cs_accordion .acc_header {
	padding: 13px 0;
	position: relative;
	cursor: pointer;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	/* text-shadow: 1px 1px black; */
}

.acc_header h3 {
	color: white;
}

.fn_cs_accordion .icon {
	width: 40px;
	height: 40px;
	min-width: 40px;
	margin-left: 20px;
	display: block;
	position: relative;
}

.fn_cs_accordion .icon span:after,
.fn_cs_accordion .icon span:before {
	content: '';
	position: absolute;
	display: block;
	background-color: #8d898e;
	transition: all .3s ease;
}

.fn_cs_accordion .icon span:before {
	width: 14px;
	height: 2px;
	top: 19px;
	left: 13px;
}

.fn_cs_accordion .icon span:after {
	width: 2px;
	height: 14px;
	bottom: 13px;
	left: 19px;
}

.fn_cs_accordion .active .icon span:after {
	height: 0;
}

.fn_cs_accordion .icon:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 100%;
	border: 3px solid transparent;
	background: linear-gradient(to bottom, #00aaff 0%,
			#ff9500 100%) border-box;
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	-webkit-mask-composite: source-out;
	mask-composite: exclude;
	background-size: 300% 300%;
	-webkit-animation: animatedgradient 4s ease alternate infinite;
	animation: animatedgradient 4s ease alternate infinite;
}

.fn_cs_accordion .acc_content {
	overflow: hidden;
	padding-top: 26px;
	padding-bottom: 27px;
	position: relative;
	display: none;
}

.fn_cs_accordion .active .acc_content {
	display: block;
}

.acc_content>p {
	color: #9FA0FF;
	text-shadow: 1px 1px 5px black;
}

.fn_cs_accordion .active .acc_header:after {
	width: 100%;
}

.fn_cs_accordion .acc_header:after {
	width: 0;
	position: absolute;
	content: '';
	height: 1px;
	background-color: #7C77B9;
	bottom: -1px;
	right: 0;
	display: block;
	transition: all .3s .3s ease;
}

.fn_cs_accordion .acc_content p {
	margin: 0;
}



#hero4 {
	position: relative;
	overflow: hidden;
}

.fn_cs_hero_slider canvas {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.fn_cs_hero_slider {
	position: relative;
	z-index: 5;
	padding: 160px 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.fn_cs_hero_slider .bg_overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.fn_cs_hero_slider .fn__maintitle {
	margin-bottom: 40px;
}

.fn_cs_hero_slider p {
	margin-bottom: 40px;
}

.fn_cs_hero_slider .left_part {
	width: 480px;
	max-width: 45%;
	padding: 0 60px 0 80px;
	-webkit-flex: none;
	-moz-flex: none;
	-ms-flex: none;
	-o-flex: none;
	flex: none;
	position: relative;
	z-index: 5;
}

.fn_cs_hero_slider .slider_part {
	width: 100%;
	position: relative;
	z-index: 5;
}

.fn_cs_hero_slider .swiper-container {
	padding: 20px 0 20px 20px;
}

.fn_cs_hero_slider .swiper-slide {
	width: 450px;
}

.fn_cs_hero_slider .item img {
	min-width: 100%;
	margin-bottom: 33%;
	opacity: 0;
}

.fn_cs_hero_slider .item {
	position: relative;
	padding: 20px;
	border-radius: 20px;
	background-color: rgba(255, 255, 255, .05);
	z-index: 2;
	transition: all .3s ease;
}

.fn_cs_hero_slider .item:before {
	border: 1px solid rgba(255, 255, 255, .5);
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 20px;
}

.fn_cs_hero_slider .item:hover:after {
	opacity: .5;
}

.fn_cs_hero_slider .item:after {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: var(--mc1);
	opacity: .15;
	border-radius: 20px;
	box-shadow: 0px 5px 20px;
	transition: all .3s ease;
}

.fn_cs_hero_slider .img_holder {
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.fn_cs_hero_slider .swiper-wrapper {
	transition-timing-function: ease-out;
}

@media(max-width: 1040px) {
	.fn_cs_hero_slider {
		padding: 130px 0;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.fn_cs_hero_slider .left_part {
		width: 100%;
		max-width: 100%;
		padding: 20px 20px 40px;
	}

	.fn_cs_hero_slider .swiper-slide {
		width: 350px;
	}
}


.fn_cs_full_slider {
	width: 100%;
	position: relative;
}

.fn_cs_full_slider .bg_overlay {
	z-index: 2;
	background: rgb(0, 0, 0);
	background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
	background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.fn_cs_full_slider .swiper-slide {
	height: 600px;
	height: 100vh;
	position: relative;
	overflow: hidden;
	z-index: 5;
}

.fn_cs_full_slider .fn__maintitle {
	position: absolute;
	bottom: 110px;
	left: 0;
	right: 0;
	z-index: 10;
	text-align: center;
	padding: 0 20px;
}

.fn_cs_full_slider .slider_nav {
	position: absolute;
	margin: 0;
	bottom: 30px;
	left: 0;
	right: 0;
	z-index: 10;
}

/* CSS specific to iOS devices */
@media not all and (min-resolution:.001dpcm) {
	.fn__gradient_title {
		background-clip: text;
		-webkit-background-clip: text;
		text-fill-color: transparent;
		display: inline;
	}

	.fn_cs_counter_list .item h3 {
		display: inline;
	}

	a.metaportal_fn_videobutton,
	.fn_cs_video a {
		color: #fff;
	}
}

.AavartanContainer {
	padding: 0 10rem;
}

@media (width<600px) {
	.AavartanContainer {
		padding: 0;
	}
}

.HomeContainer {
	padding: 0 15rem;
}

@media (width<1024px) {
	.HomeContainer {
		padding: 0 2rem;
	}
}

@media (width<769px) {
	.HomeContainer {
		padding: 0 1rem;
	}
}

@media (width<426px) {
	.HomeContainer {
		padding: 0;
	}
}

.metaportal_fn_button_2 {
	font-size: 16px;
	border-radius: 10px;
	border: 3px solid;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	width: 70%;
	color: rgba(255, 255, 255, 0.8);
	border-color: #974EC3C9;
	margin: 2rem 0rem 0rem 0;
	text-decoration: none;
	position: relative;
	z-index: 1;
}

@media(width<600px) {
	.metaportal_fn_button_2 {
		width: 100%;
	}
}

.metaportal_fn_button_2:hover {
	color: white;
	background: linear-gradient(to bottom, #504099c5 20%, #fe7be6c8 100%);
	border: 2px solic;
	border-bottom-color: #FE7BE5;
	border-top-color: #504099;
	cursor: pointer;
}

.metaportal_fn_button_3 {
	font-size: 20px;
	font-weight: 600;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	width: 100%;
	color: rgba(255, 255, 255, 1);
	background: linear-gradient(to bottom, #504099c5 20%, #fe7be6c8 100%);
	margin: 3rem 0rem 0rem 2rem;
	text-decoration: none;
	box-shadow: 2px 4px 15px black;
	transition: color 0.3s ease, background 0.3s ease;
	/* Add transition for color and background */
}

@media (max-width: 600px) {

	/* Correct the media query syntax */
	.metaportal_fn_button_3 {
		width: 100%;
		margin: 0;
		margin-top: 1rem;
		font-size: 14px;
	}
}

.metaportal_fn_button_3:hover {
	color: white;
	background: linear-gradient(to bottom, #fe7be6c8 20%, #504099c5 100%);
	cursor: pointer;
}



.metaportal_fn_button_4 {
	width: 220px;
	height: 50px;
	font-size: 18px;
	/* border-radius: 20px; */
	border: 4px solid;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-weight: 500;
	border-image: linear-gradient(to right, #4c90d5, #FE7BE5);
	/* Define your linear gradient colors here */
	border-image-slice: 1;
	/* Keeps the entire gradient within the border */
	border-image-repeat: stretch;
	margin: 1.5rem 0rem;
	position: relative;
	z-index: 1;
}

@media(width<600px) {
	.metaportal_fn_button_4 {
		width: 100%;
	}
}

.metaportal_fn_button_4:hover {
	color: white;
	background: linear-gradient(to right, #4c90d5, #FE7BE5);
	cursor: pointer;
}


.Aavartanscrolled {
	background: url("./assets/Aavartan New/bgavartan.jpg") no-repeat center center;
	background-size: cover;
	background-attachment: fixed;
	transition: background-color 0.9s ease;
	animation: rain 0.3s linear infinite;
	z-index: -3;
}

.Aavartanscrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("./assets/Aavartan New/lightining.png");
	top: -100px;
	transform: rotate(180deg);
	animation: lightining 4s linear infinite;
	opacity: 0.3;
}


@keyframes lightining {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	21% {
		opacity: 0.4;
	}

	25% {
		opacity: 0;
	}

	30% {
		opacity: 0;
	}

	31% {
		opacity: 0.4;
	}

	35% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}


.Aavartanscrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-color: rgba(0, 0, 0, 0.5); */
	/* background:url("./assets/Aavartan New/AB2.webp"); */
	/* Adjust the opacity (last value) as needed */
	z-index: 1;
	/* Ensure the overlay is above the background image */
}

.Vigyaanscrolled {
	background: url("./assets/images/vigyaanbg.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-position: top;
	/* transition: background-color 0.9s ease; */
}

.Vigyaanscrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	/* Adjust the opacity (last value) as needed */
	z-index: 0;
	/* Ensure the overlay is above the background image */
}

.Ignitescrolled {
	background: url("./assets/images/vigyaanbg.jpg");
	background-size: cover;
	background-attachment: fixed;
	/* transition: background-color 0.9s ease; */
}

.Ignitescrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	/* Adjust the opacity (last value) as needed */
	z-index: 0;
	/* Ensure the overlay is above the background image */
}

/* .Homescrolled {
	background: url("./assets/the-minions-celebrating-in-despicable-me-2.avif");
	background-size: cover;
	background-attachment: fixed;
	
} */
.TeamScrolled{
	background: url("./assets/the-minions-celebrating-in-despicable-me-2.avif");
	background-size: cover;
	background-attachment: fixed;
	/* transition: background-color 0.9s ease; */
}
.TeamScrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 0;
}
.Eventscrolled {
	background: url("./assets/event.webp");
	background-size: cover;
	background-attachment: fixed;
}
.Eventscrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
	z-index: 0;
  }

/* .Homescrolled::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2); */
	/* Adjust the opacity (last value) as needed */
	/* z-index: 0; */
	/* Ensure the overlay is above the background image */
/* } */
/* .Homescrolled::before { */
/* content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65); */
/* Adjust the opacity (last value) as needed */
/* z-index: 0; */
/* Ensure the overlay is above the background image */
/* backdrop-filter: brightness(1) blur(0px); */
/* } */

.dark-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	transition: background-color 0.5s ease;
	/* backdrop-filter: blur(1px); */
}

.dark-overlay.active {
	background-color: rgba(0, 0, 0, 0.5);
}

.custom-cursor {
	cursor: url("./assets/images/cursor.svg") 10 10, auto;
}

.goodbtn {
	width: 220px;
	height: 50px;
	font-size: 18px;
	/* border-radius: 20px; */
	border: 4px solid;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-weight: 500;
	border-image: linear-gradient(to right, #d58e4c, #380303);
	/* Define your linear gradient colors here */
	border-image-slice: 1;
	/* Keeps the entire gradient within the border */
	background-color: #610707;
	border-image-repeat: stretch;
	margin: 1rem 0rem;
	transition: transform .2s ease;
}

.goodbtn:hover {
	color: white;
	background: linear-gradient(to right, #040100, #230101);
	transform: scale(1.1);
	/* border: 2px solic;
 border-bottom-color:#FE7BE5 ;
 border-top-color: #504099 ; */
	cursor: pointer;
}
.gallery-btn{
	border-image: none;
	border: none;
}
.goodbtn:active{
	transform: scale(.9);
}

@font-face {
	font-family: 'angora';
	src: url('./assets/font/angora.otf') format('opentype');
}

@font-face {
	font-family: 'poppins';
	src: url('./assets/font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'kanit';
	src: url('./assets/font/Kanit-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'AavartanFont';
	src: url('./assets/font/IrishGrover-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'VigyaanFont';
	src: url('./assets/font/Minecraftia-Regular.ttf') format('truetype')
}

@font-face {
	font-family: 'Kung Fu Panda';
	src: url("./assets/font/KungFuPandaRegular-xYjV.ttf");
}

@font-face {
	font-family: 'Blood Crow';
	src: url("./assets/font/bloodcrow.ttf");
}

@font-face {
	font-family: 'carve';
	src: url("./assets/font/WoodCarving-ZpJmK.ttf");
}

@font-face {
	font-family: 'chisled';
	src: url("./assets/font/Chiseled-KExl.ttf");
}

@font-face {
	font-family: 'Minecraft-title';
	src: url("./assets/font/MinecraftTen-VGORe.ttf");
}

@media(width<600px) {
	.blog__item {
		padding: 5px;
	}

	.news_part {
		margin: 0 !important;
	}
}

.rimageaav {
	position: absolute;
	top: 237vh;
	right: 0%;
	height: 250px;
	filter: grayscale(0.5);

}

@media (max-width: 769px) {
	.rimageaav {
		position: absolute;
		height: 180px;
		top: 250vh;
		margin-right: 25%;
	}
}

@media (max-width: 426px) {
	.rimageaav {
		position: absolute;
		height: 130px;
		top: 250vh;
		margin-right: 15%;
	}
}


.funkybtn {
	text-decoration: none;
	border: 1px solid rgb(146, 148, 248);
	position: relative;
	overflow: hidden;
	font-size: 1.3rem;
	font-weight: 600;
	padding: 0.6rem 2rem;
	color: #f4f4f4;
	text-transform: uppercase;
}

.funkybtn {
	box-shadow: 1px 1px 25px 10px rgba(146, 148, 248, 0.4);
}

.funkybtn:before {
	content: "";
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(120deg,
			transparent,
			rgba(146, 148, 248, 0.4),
			transparent);
	transition: all 650ms;
}

.funkybtn:hover:before {
	left: 100%;
}

.vigyaan-title {
	text-align: center;
	font-size: 4rem;
	margin: 50px 0px 0 0px;
	font-family: 'Minecraft-title';
	z-index: 3;
	/* background: linear-gradient(to bottom, #4c90d5 20%,
			#FE7BE5 100%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text; */
	text-shadow: 3px 5px 5px rgb(133, 133, 133);
}



.cta {
	display: flex;
	padding: 5px 45px;
	text-decoration: none;
	font-family: 'kanit', sans-serif;
	font-size: 25px;
	font-weight: 600;
	color: white;
	background: #540303;
	transition: 1s;
	box-shadow: 6px 6px 0 rgb(251, 167, 12);
	transform: skewX(-15deg);
	justify-content: center;
	align-items: center;
}

@media(width<600px) {
	.cta {
		justify-content: center;
		align-items: center;
		font-size: 17px;
		padding: 3px 15px;
	}
}

.cta:focus {
	outline: none;
}

.cta:hover {
	transition: 0.5s;
	box-shadow: 10px 10px 0 #FBC638;
}

.cta span:nth-child(2) {
	transition: 0.5s;
	margin-right: 0px;
}

.cta:hover span:nth-child(2) {
	transition: 0.5s;
	margin-right: 45px;
}

.cta span {
	transform: skewX(15deg)
}

.cta span:nth-child(2) {
	width: 20px;
	margin-left: 30px;
	position: relative;
	top: 12%;
}

/**************SVG****************/

path.one {
	transition: 0.4s;
	transform: translateX(-60%);
}

path.two {
	transition: 0.5s;
	transform: translateX(-30%);
}

.cta:hover path.three {
	animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
	transform: translateX(0%);
	animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
	transform: translateX(0%);
	animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
	0% {
		fill: white;
	}

	50% {
		fill: #FBC638;
	}

	100% {
		fill: white;
	}
}