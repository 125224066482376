.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;   
}

.begin{
    color: white;
}
h1{
    color: #fff;
    font-size: 3rem;
}

h4{
    font-size: 25px;
    margin-bottom: 30px;
    color: #9D44C0;
}

.CardContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 30px;
    row-gap: 50px;
}

@media (max-width:1200px){
    .CardContainer{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 5vw;
    }
    .Container{
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width:900px){
    .CardContainer{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        row-gap: 3rem;
    }
}
@media(max-width:550px){
    h4{
        font-size: 25px;
    }
}