.tech-team-card {
    background: linear-gradient(135deg, #ffcb05, #3c5aa6);
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .tech-team-card::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    width: 100px;
    height: 100px;
    background: url('https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/items/poke-ball.png') no-repeat;
    background-size: contain;
    opacity: 0.2;
  }
  
  .tech-team-card:hover {
    transform: translateY(-10px) rotate(2deg);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  .card-content {
    padding: 30px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    margin: 10px;
  }
  
  .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #ffcb05;
    margin-bottom: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    color: #3c5aa6;
    margin: 0 0 5px;
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  p {
    color: #2a75bb;
    margin: 0 0 15px;
    font-weight: bold;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-links a {
    color: #3c5aa6;
    font-size: 28px;
    transition: all 0.3s ease;
  }
  
  .social-links a:hover {
    color: #ffcb05;
    transform: scale(1.2);
  }
  
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  
  .tech-team-card {
    animation: float 3s ease-in-out infinite;
  }